import React, { useState } from 'react';
import Sites from './SettingsTabs/Sites';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import '../style/Theme.css';
import common from '../strings/common.json';
import Header from './SettingsTabs/Header';
import Departments from './SettingsTabs/Departments';
import Tasks from './SettingsTabs/Tasks';
import Machines from './SettingsTabs/Machines';
import Companies from './SettingsTabs/Companies';
import Department from './Dialogs/Department';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: 0 }} p={3}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    padding: 0,
    margin: 0,
  },
  indicator: {
    height: '3px',
    backgroundColor: '#693573',
  },
  topbar: {
    backgroundColor: '#7DA0CD',
    color: 'white',
    boxShadow: 'none',
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const [showAddSite, setShowAddSite] = useState(false);
  const [showAddDepartment, setShowAddDepartment] = useState(false);
  const [showAddTask, setShowAddTask] = useState(false);
  const [showAddMachine, setShowAddMachine] = useState(false);
  const [showAddCompany, setShowAddCompany] = useState(false);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const addSite = () => {
    setShowAddSite(true);
  };
  const addDepartment = () => {
    setShowAddDepartment(true);
  };
  const addTask = () => {
    setShowAddTask(true);
  };
  const addMachine = () => {
    setShowAddMachine(true);
  };
  const addCompany = () => {
    setShowAddCompany(true);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.topbar}>
        <Tabs
          value={value}
          onChange={handleTabChange}
          variant="fullWidth"
          classes={{
            indicator: classes.indicator,
          }}
        >
          <Tab label={common.settingsPage.header.sites} {...a11yProps(0)} />
          <Tab label={common.settingsPage.header.departments} {...a11yProps(1)} />
          <Tab label={common.settingsPage.header.tasks} {...a11yProps(2)} />
          <Tab label={common.settingsPage.header.machines} {...a11yProps(3)} />
          <Tab label={common.settingsPage.header.companies} {...a11yProps(4)} />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <Header
          titleName={common.settingsPage.site}
          buttonName={common.addingOptions.addSite}
          handleClick={addSite}
        />
        <Sites open={showAddSite} setOpen={setShowAddSite} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Header
          titleName={common.settingsPage.department}
          buttonName={common.addingOptions.addDepartment}
          handleClick={addDepartment}
        />
        <Departments open={showAddDepartment} />
        <Department open={showAddDepartment} setOpen={setShowAddDepartment} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Header
          titleName={common.settingsPage.task}
          buttonName={common.addingOptions.addTask}
          handleClick={addTask}
        />
        <Tasks open={showAddTask} setOpen={setShowAddTask} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Header
          titleName={common.settingsPage.machineNumber}
          secondTitleName={common.settingsPage.machineType}
          buttonName={common.addingOptions.addTerminator}
          handleClick={addMachine}
        />
        <Machines open={showAddMachine} setOpen={setShowAddMachine} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Header
          titleName={common.settingsPage.companyName}
          buttonName={common.addingOptions.addCompany}
          handleClick={addCompany}
        />
        <Companies open={showAddCompany} setOpen={setShowAddCompany} />
      </TabPanel>
    </div>
  );
}
