export const getHoursAndMinutesArray = (field: any) => {
  const hoursAndMinutes = [];
  hoursAndMinutes.push(Math.floor(field / 60));
  hoursAndMinutes.push(field % 60);
  return hoursAndMinutes;
};

export const formatToHoursAndMinutes = (field: any) => {
  const hoursAndMinutes = getHoursAndMinutesArray(field);
  const min =
    hoursAndMinutes[1].toString().length === 1
      ? '0' + hoursAndMinutes[1].toString()
      : hoursAndMinutes[1].toString();
  return hoursAndMinutes[0] + 'h ' + min + 'm';
};

export const getMinutes = (hours: any, minutes: any) => {
  let minutesSum = parseInt(hours, 10) * 60;
  minutesSum = minutesSum + parseInt(minutes, 10);
  return minutesSum;
};
