import { useContext, useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { useHistory, useLocation } from 'react-router-dom';
import common from '../strings/common.json';
import assign from '../assets/assign.svg';
import reports from '../assets/reports.svg';
import bills from '../assets/bills.svg';
import users from '../assets/users.svg';
import settings from '../assets/settings.svg';
import logout from '../assets/logout.svg';
import back from '../assets/back.svg';
import '../style/layout/MainMenu.css';
import { AuthContext } from '../components/context';

const MainMenu = (): JSX.Element => {
  const [menuOpen, setMenuOpen] = useState(false);
  const history = useHistory();
  const { signOut } = useContext(AuthContext);
  const location = useLocation();
  
  return (
    <div className="menu-container">
      {location.pathname.startsWith('/invoice/') ? (
        <img src={back} alt="back" onClick={() => history.goBack()} className="back-button" />
      ) : (
        <Menu isOpen={menuOpen} onStateChange={(e) => setMenuOpen(e.isOpen)}>
          <div
            id="assign"
            className="menu-item"
            onClick={() => {
              history.push('/assign');
              setMenuOpen(false);
            }}
          >
            <img src={assign} alt="assign task" />
            {common.menu.assign}
          </div>
          <div
            id="reports"
            className="menu-item"
            onClick={() => {
              history.push('/reports');
              setMenuOpen(false);
            }}
          >
            <img src={reports} alt="reports" />
            {common.menu.reports}
          </div>
          <div
            id="bills"
            className="menu-item"
            onClick={() => {
              history.push('/invoices');
              setMenuOpen(false);
            }}
          >
            <img src={bills} alt="bills" />
            {common.menu.bills}
          </div>
          <div
            id="employees"
            className="menu-item"
            onClick={() => {
              history.push('/employees');
              setMenuOpen(false);
            }}
          >
            <img src={users} alt="users" />
            {common.menu.users}
          </div>
          <div
            id="settings"
            className="menu-item"
            onClick={() => {
              history.push('/settings');
              setMenuOpen(false);
            }}
          >
            <img src={settings} alt="settings" />
            {common.menu.settings}
          </div>
          <div
            id="logout"
            className="menu-item"
            onClick={() => signOut()}
            style={{ cursor: 'pointer' }}
          >
            <img src={logout} alt="logout" />
            {common.menu.logout}
          </div>
        </Menu>
      )}
    </div>
  );
};

export default MainMenu;
