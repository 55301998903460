import { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import common from '../strings/common.json';
import colors from '../strings/colors.json';
import { getInvoice } from '../api/invoices';
import { useParams } from 'react-router-dom';
import { formatToFinnishDate } from '../components/helpers/getDate';
import getKm from '../components/helpers/getKm';
import { formatToHoursAndMinutes } from '../components/helpers/getWorkedTime';
import { formatEuros } from '../components/helpers/getEuros';

function Invoice() {
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const [reports, setReports] = useState([]);

  const columns = [
    { title: common.reportsTable.employee, field: 'assignedUserName' },
    {
      title: common.reportsTable.site,
      field: 'siteName',
    },
    { title: common.reportsTable.task, field: 'taskName' },
    { title: common.reportsTable.machine, field: 'machineNumber' },
    {
      title: common.reportsTable.date,
      field: 'completedDate',
      render: (rowData: any) => formatToFinnishDate(rowData.completedDate),
    },
    {
      title: common.reportsTable.workingHours,
      field: 'workedMinutes',
      render: (rowData: any) => formatToHoursAndMinutes(rowData.workedMinutes),
    },
    {
      title: common.reportsTable.drivenKilometers,
      field: 'distance',
      render: (rowData: any) => getKm(rowData.originalDistance),
    },
    {
      title: common.reportsTable.cost,
      field: 'calculatedCost',
      render: (rowData: any) => formatEuros(rowData.calculatedCost),
    },
  ];

  useEffect(() => {
    const get = async () => {
      const response: any = await getInvoice(invoiceId);
      if (response !== null) {
        response.reports.sort((a: any, b: any) => (a.completedDate > b.completedDate ? 1 : -1));
        setReports(response.reports);
      }
    };
    get();
  }, []);

  return (
    <div>
      <MaterialTable
        title=""
        localization={{
          header: {
            actions: '',
          },
        }}
        columns={columns}
        data={reports}
        options={{
          headerStyle: { backgroundColor: colors.tableHeaderGrey },
          actionsColumnIndex: -1,
          pageSize: 10,
        }}
      />
    </div>
  );
}

export default Invoice;
