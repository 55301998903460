import { MenuItem, TextField } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { getMonthName, reverseMonthName } from '../../components/helpers/getDate';
import { filterReports } from '../../api/reports';
import BasicButton from '../../components/layout/BasicButton';
import common from '../../strings/common.json';
import '../../style/layout/Forms.css';

const ReportsFiltering = ({
  reports,
  setFilteredReports,
}: {
  reports: any[];
  setFilteredReports: (reports: any) => void;
}): JSX.Element => {
  const [employees, setEmployees] = useState<string[]>([]);
  const [employee, setEmployee] = useState('');
  const [sites, setSites] = useState<string[]>([]);
  const [site, setSite] = useState('');
  const [department, setDepartment] = useState('');
  const [departments, setDepartments] = useState<string[]>([]);
  const [tasks, setTasks] = useState<string[]>([]);
  const [task, setTask] = useState('');
  const [machines, setMachines] = useState<string[]>([]);
  const [machine, setMachine] = useState('');
  const [monthYearDates, setMonthYearDates] = useState<string[]>([]);
  const [monthYearDate, setMonthYearDate] = useState('');

  useEffect(() => {
    if (reports !== undefined && reports.length !== 0) {
      reports.sort((a: any, b: any) => (a.completedDate < b.completedDate ? 1 : -1));
      const dateSet = Array.from(
        new Set(reports.map((r) => getMonthName(r.completedDate, common)))
      );
      const employeesSet = Array.from(new Set(reports.map((r) => r.assignedUserName)));
      const sitesSet = Array.from(new Set(reports.map((r) => r.siteName)));
      const tasksSet = Array.from(new Set(reports.map((r) => r.taskName)));
      const machinesSet = Array.from(new Set(reports.map((r) => r.machineNumber)));
      const departmentSet = Array.from(new Set(reports.map((r) => r.departmentName)));
      setMonthYearDates(dateSet);
      setEmployees(employeesSet);
      setSites(sitesSet);
      setTasks(tasksSet);
      setMachines(machinesSet);
      setDepartments(departmentSet);
      filter();
    }
  }, [reports]);

  const filter = async (month = 0, year = 0) => {
    if (monthYearDate !== '' && month === 0 && year === 0) {
      const monthYearArray = reverseMonthName(monthYearDate, common);
      month = monthYearArray[0];
      year = monthYearArray[1];
    }
    const response = await filterReports(year, month, department, employee, site, task, machine);
    response.sort((a: any, b: any) => (a.completedDate < b.completedDate ? 1 : -1));
    setFilteredReports(response);
  };

  const resetFilters = () => {
    const reset = async () => {
      setEmployee('');
      setSite('');
      setTask('');
      setMachine('');
      setDepartment('');
      let month = 0;
      let year = 0;
      if (monthYearDate !== '') {
        const monthYearArray = reverseMonthName(monthYearDate, common);
        month = monthYearArray[0];
        year = monthYearArray[1];
      }
      const response = await filterReports(year, month, '', '', '', '', '');
      response.sort((a: any, b: any) => (a.completedDate < b.completedDate ? 1 : -1));
      setFilteredReports(response);
    };
    reset();
  };

  return (
    <>
      <TextField
        className="reports-filter"
        select
        value={monthYearDate}
        id="date"
        name="date"
        label={common.reportsTable.month}
        onChange={(e) => {
          setMonthYearDate(e.target.value);
        }}
      >
        <MenuItem value="">
          <em>{common.reportsTable.month}</em>
        </MenuItem>
        {monthYearDates.map((date: any) => (
          <MenuItem key={date} value={date}>
            {date}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        className="reports-filter"
        select
        value={site}
        id="site"
        name="site"
        label={common.reportsTable.site}
        onChange={(e) => {
          setSite(e.target.value);
        }}
      >
        <MenuItem value="">
          <em>{common.reportsTable.site}</em>
        </MenuItem>
        {sites.map((siteOption: any) => (
          <MenuItem key={siteOption} value={siteOption}>
            {siteOption}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        className="reports-filter"
        select
        value={department}
        id="department"
        name="department"
        label={common.reportsTable.department}
        onChange={(e) => {
          setDepartment(e.target.value);
        }}
      >
        <MenuItem value="">
          <em>{common.reportsTable.department}</em>
        </MenuItem>
        {departments.map((department: any) => (
          <MenuItem key={department} value={department}>
            {department}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        className="reports-filter"
        select
        value={employee}
        id="employee"
        name="employee"
        label={common.reportsTable.employee}
        onChange={(e) => {
          setEmployee(e.target.value);
        }}
      >
        <MenuItem value="">
          <em>{common.reportsTable.employee}</em>
        </MenuItem>
        {employees.map((employeeOption: any) => (
          <MenuItem key={employeeOption} value={employeeOption}>
            {employeeOption}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        className="reports-filter"
        select
        value={task}
        id="task"
        name="task"
        label={common.reportsTable.task}
        onChange={(e) => {
          setTask(e.target.value);
        }}
      >
        <MenuItem value="">
          <em>{common.reportsTable.task}</em>
        </MenuItem>
        {tasks.map((taskOption: any) => (
          <MenuItem key={taskOption} value={taskOption}>
            {taskOption}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        className="reports-filter"
        select
        value={machine}
        id="machine"
        name="machine"
        label={common.reportsTable.machine}
        onChange={(e) => {
          setMachine(e.target.value);
        }}
      >
        <MenuItem value="">
          <em>{common.reportsTable.machine}</em>
        </MenuItem>
        {machines.map((machineOption: any) => (
          <MenuItem key={machineOption} value={machineOption}>
            {machineOption}
          </MenuItem>
        ))}
      </TextField>
      <BasicButton className="filter-button" onClick={() => filter()}>
        {common.reportsTable.filterReports}
      </BasicButton>
      <BasicButton className="filter-button" onClick={resetFilters}>
        {common.reportsTable.reset}
      </BasicButton>
    </>
  );
};

export default ReportsFiltering;
