import { useEffect, useContext, useState } from 'react';
import MaterialTable from 'material-table';
import { Popover } from '@material-ui/core';
import '../../style/layout/Report.css';
import common from '../../strings/common.json';
import colors from '../../strings/colors.json';
import fontSizes from '../../strings/fontSizes.json';
import { deleteReport, getMapImage, getReports } from '../../api/reports';
import { formatToFinnishDate } from '../../components/helpers/getDate';
import { formatEuros } from '../../components/helpers/getEuros';
import ReportsFiltering from './ReportsFiltering';
import Report from '../Dialogs/Report';
import { DialogContext } from '../../components/context';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import getKm from '../../components/helpers/getKm';
import { formatToHoursAndMinutes } from '../../components/helpers/getWorkedTime';
import getTotalCost from '../../components/helpers/getTotalCost';
import { WorkSheet, writeFileXLSX, utils, WorkBook  } from 'xlsx';
var XLSX = require('xlsx');

interface report {
  id: string,
  workedMinutes: number,
  originalWorkedMinutes: number,
  comments?: string,
  assignedUserName: string,
  assignedUserPersonnelNumber: string,
  departmentName: string,
  siteName: string,
  taskName: string,
  taskAccount: number,
  costCenter: string,
  calculatedCost: number,
  useSplitCost: boolean,
  splitCostTooltip: string,
  completedDate: string,
  moreInfo?: string,
  machineNumber: string,
  distance: number,
  originalDistance: number,
  tableData: {
      id: number
  }
}

const vat = 124;

function Reports() {
  const [reports, setReports] = useState<report[]>([]);
  const [mapImage, setMapImage] = useState('');
  const [filteredReports, setFilteredReports] = useState<report[]>([]);
  const [refreshResults, setRefreshResults] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState<report>();
  const { exportExcel, setExportExcel } = useContext(DialogContext);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const SplitCostTooltip = withStyles({
    arrow: {
      color: colors.highPriorityTooltipBackground,
    },
    tooltip: {
      fontSize: fontSizes.highPriorityTooltipText,
      color: colors.highPriorityTooltipText,
      backgroundColor: colors.highPriorityTooltipBackground,
    },
  })(Tooltip);

  const columns = [
    { title: common.reportsTable.employee, field: 'assignedUserName' },
    { title: common.reportsTable.site, field: 'siteName' },
    { title: common.reportsTable.department, field: 'departmentName' },
    { title: common.reportsTable.task, field: 'taskName' },
    {
      title: common.reportsTable.moreInfo,
      field: 'moreInfo',
      render: (rowData: any) => (
        <div className="high-priority-mark">
          {rowData.moreInfo !== '' && rowData.moreInfo !== null ? (
            <SplitCostTooltip title={<>{rowData.moreInfo}</>} placement="top" arrow>
              <img
                className="exclamation-mark"
                src={require('../../assets/exclamation.svg').default}
                alt="exclamation icon"
              />
            </SplitCostTooltip>
          ) : null}
        </div>
      ),
    },
    {
      title: common.reportsTable.comments,
      field: 'comments',
      render: (rowData: any) => (
        <div className="high-priority-mark">
          {rowData.comments !== '' && rowData.comments !== null ? (
            <SplitCostTooltip title={<>{rowData.comments}</>} placement="top" arrow>
              <img
                className="exclamation-mark"
                src={require('../../assets/exclamation.svg').default}
                alt="exclamation icon"
              />
            </SplitCostTooltip>
          ) : null}
        </div>
      ),
    },
    { title: common.reportsTable.machine, field: 'machineNumber' },
    {
      title: common.reportsTable.date,
      field: 'completedDate',
      render: (rowData: any) => formatToFinnishDate(rowData.completedDate),
    },
    {
      title: common.reportsTable.workingHours,
      field: 'workedMinutes',
      render: (rowData: any) => (
        <>
          <span>{formatToHoursAndMinutes(rowData.workedMinutes)}</span>
          {rowData.originalWorkedMinutes !== rowData.workedMinutes ? (
            <SplitCostTooltip
              title={
                <>
                  {common.exclamationPopup.modifiedTime}
                  <br />
                  {common.exclamationPopup.originalTime}
                  {formatToHoursAndMinutes(rowData.originalWorkedMinutes)}
                </>
              }
              placement="top"
              arrow
            >
              <img
                className="exclamation-mark"
                src={require('../../assets/exclamation.svg').default}
                alt="exclamation icon"
              />
            </SplitCostTooltip>
          ) : null}
        </>
      ),
    },
    {
      title: common.reportsTable.drivenKilometers,
      field: 'distance',
      render: (rowData: any) => (
        <div className="high-priority-mark">
          <span>{getKm(rowData.distance)}</span>
          {rowData.originalDistance !== rowData.distance ? (
            <SplitCostTooltip
              title={
                <>
                  {common.exclamationPopup.modifiedDistance}
                  <br />
                  {common.exclamationPopup.originalDistance} {getKm(rowData.originalDistance)}
                </>
              }
              placement="top"
              arrow
            >
              <img
                className="exclamation-mark"
                src={require('../../assets/exclamation.svg').default}
                alt="exclamation icon"
              />
            </SplitCostTooltip>
          ) : null}
          <div className="image-box">
            <img
              alt="map"
              className="center-img"
              src={require('../../assets/map.svg').default}
              onClick={() => downloadMap(rowData.id)}
            />
          </div>
        </div>
      ),
    },
    {
      title: common.reportsTable.account,
      field: 'taskAccount',
      render: (rowData: any) => rowData.taskAccount || '',
    },
    { 
      title: common.reportsTable.costCenter,
      field: 'costCenter',
      render: (rowData: any) => rowData.costCenter || '',
    },
    {
      title: common.reportsTable.cost,
      field: 'calculatedCost',
      render: (rowData: any) => {
        const euros = formatEuros(rowData.calculatedCost);
        if (rowData.useSplitCost) {
          return (
            <div className="high-priority-mark">
              <span>{euros}</span>
              <SplitCostTooltip title={rowData.splitCostTooltip} placement="top" arrow>
                <img
                  className="exclamation-mark"
                  src={require('../../assets/exclamation.svg').default}
                  alt="exclamation icon"
                />
              </SplitCostTooltip>
            </div>
          );
        }
        return euros;
      },
    },
    {
      title: common.reportsTable.vat,
      render: (_rowData: any) => vat,
    },
    {
      title: common.reportsTable.total,
      render: (rowData: any) => getTotalCost(rowData.calculatedCost, vat),
    },
  ];

  const deleteSelectedReport = async (_e: any, rowData: report) => {
    if (window.confirm(common.reportsDialog.confirmDelete)) {
      const response = await deleteReport(rowData.id);
      if (response !== 200) {
        window.alert(common.reportsDialog.errorDelete);
        return;
      }
      setRefreshResults(true);
    }
  };

  const downloadMap = async (reportID: string) => {
    const response = await getMapImage(reportID);
    if (response != null) {
      setMapImage(response['img']);
      openPopover();
    } else {
      window.alert(common.noMap);
    }
  };

  const openPopover = () => {
    setAnchorEl('open');
  };

  const actions = [
    {
      icon: 'edit',
      tooltip: common.reportsDialog.edit,
      // @ts-ignore
      onClick: (e: any, rowData: any) => selectReport(e, rowData),
    },
    {
      icon: 'delete',
      tooltip: common.reportsDialog.delete,
      // @ts-ignore
      onClick: (e: any, rowData: any) => deleteSelectedReport(e, rowData),
    },
  ];

  const selectReport = (_e: any, rowData: report) => {
    setSelectedReport(rowData);
    setOpen(true);
  };

  useEffect(() => {
    const get = async () => {
      const response = await getReports();
      setReports(response);
    };
    if (refreshResults) {
      get();
      setRefreshResults(false);
    }
  }, [refreshResults]);

  useEffect(() => {
    if (exportExcel) {
      exportDataAsExcel(filteredReports);
      setExportExcel(false);
    }
  }, [exportExcel, filteredReports, setExportExcel]);

  const exportDataAsExcel = (reports: report[]) => {
    const xlsxData = formatExcelData(reports)
    const worksheet: WorkSheet = utils.json_to_sheet(xlsxData);
    const workbook: WorkBook = { SheetNames: [], Sheets: {} };

    const formatColumnsIndex = [1, 3, 18, 19];
    const numberFormat = "€0.00";
    formatColumnsIndex.forEach((col:number) =>{
      formatColumn(worksheet, col, numberFormat);
    });

    utils.book_append_sheet(workbook, worksheet, 'Sivu 1')
    writeFileXLSX(workbook, 'Raportti.xlsx');
  }

  const formatColumn = (worksheet: WorkSheet, col: Number, fmt: String) => {
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    // note: range.s.r + 1 skips the header row
    for (let row = range.s.r + 1; row <= range.e.r; ++row) {
      const ref = XLSX.utils.encode_cell({ r: row, c: col })
      if (worksheet[ref] && worksheet[ref].t === 'n') {
        worksheet[ref].z = fmt
      }
    }
  }

  const formatExcelData = (reports: report[]) => {
    const formattedData = reports.map((i: report) => formatExcelRow(i));
    return formattedData;
  };

  const formatExcelRow = (row: report) => {
    const formattedRow = {
      [common.reportsTable.account]: row.taskAccount,
      [common.reportsTable.costCenter]: row.costCenter,
      [common.reportsTable.vat]: vat,
      [common.reportsTable.cost]: row.calculatedCost,
      [common.reportsTable.moreInfo]: row.moreInfo,
      [common.reportsTable.comments]: row.comments,
      [common.reportsTable.employee]: row.assignedUserName,
      [common.reportsTable.site]: row.siteName,
      [common.reportsTable.department]: row.departmentName,
      [common.reportsTable.task]: row.taskName,
      [common.reportsTable.machine]: row.machineNumber,
      [common.reportsTable.date]: formatToFinnishDate(row.completedDate),
      [common.reportsTable.workingHours]: formatToHoursAndMinutes(row.workedMinutes),
      [common.reportsTable.workingHoursMinutes]: Number(`${row.workedMinutes}`),
      [common.reportsTable.originalWorkingHours]: formatToHoursAndMinutes(row.originalWorkedMinutes),
      [common.reportsTable.originalWorkingHoursMinutes]: Number(`${row.originalWorkedMinutes}`),
      [common.reportsTable.drivenKilometers]: getKm(row.distance),
      [common.reportsTable.originalDrivenKilometers]: getKm(row.originalDistance),
      [common.reportsTable.regionalCollectionPoint]: '',
      [common.reportsTable.ecopoints]: '',
    };

    if (row.splitCostTooltip) {
      formattedRow[common.reportsTable.regionalCollectionPoint] = Number(getCollectionPointValue(row.splitCostTooltip));
      formattedRow[common.reportsTable.ecopoints] = Number(getEcoPointValue(row.splitCostTooltip));
    }

    return formattedRow;
  };
  
  const getCollectionPointValue = (tooltip: string) => {
    const collectionPointText = tooltip.split(', Ekopiste ')[0];
    return collectionPointText.split('e ')[1].replace(',', '.').replace('€', '');
  };

  const getEcoPointValue = (tooltip: string) => {
    return tooltip.split(', Ekopiste ')[1].replace(',', '.').replace('€', '');
  };

  return (
    <div>
      <ReportsFiltering reports={reports} setFilteredReports={setFilteredReports} />
      <MaterialTable
        title=""
        localization={{
          header: {
            actions: '',
          },
        }}
        columns={columns}
        data={filteredReports}
        actions={actions}
        options={{
          headerStyle: { backgroundColor: colors.tableHeaderGrey },
          actionsColumnIndex: -1,

          pageSize: 10,
        }}
      />
      <Report
        open={open}
        setOpen={setOpen}
        selectedReport={selectedReport}
        refreshPage={() => setRefreshResults(true)}
        setSelectedReport={setSelectedReport}
      />

      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        id={Boolean(anchorEl) ? 'simple-popover' : undefined}
        onClose={() => {
          setAnchorEl(null);
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'center',
        }}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'center',
        }}
      >
        <div className="report-popover">
          <img className="map-image centerImg" src={'data:image/png;base64, ' + mapImage} alt="Työtehtävän karttakuva" />
        </div>
      </Popover>
    </div>
  );
}

export default Reports;
