import { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContext } from '../../components/context';
import { Formik, Form } from 'formik';
import { DialogContentText } from '@material-ui/core';
import DialogButtons from '../../components/layout/DialogButtons';
import { MenuItem } from '@material-ui/core';
import common from '../../strings/common.json';
import { addAssignmentApi, updateAssignmentApi } from '../../api/assignment';

interface AssignJobProps {
  sites: any;
  employees: any;
  selectedAssignment: any;
  setSelectedAssignment: any;
}

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    paddingBottom: '5vh',
  },
  MuiDialogContentText: {
    fontSize: 18,
    color: 'black',
    paddingTop: 20,
  },
}));

function AssignJob({
  sites,
  employees,
  selectedAssignment,
  setSelectedAssignment,
}: AssignJobProps) {
  const { assignJob, setAssignJob } = useContext(DialogContext);
  const classes = useStyles();

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      classes={{ paper: classes.dialogPaper }}
      open={assignJob}
    >
      <Formik
        initialValues={
          selectedAssignment
            ? {
                site: selectedAssignment.site.id ?? '',
                department: selectedAssignment.department.id ?? '',
                task: selectedAssignment.task.id ?? '',
                employee: selectedAssignment.user ? selectedAssignment.user.id : '',
                moreInfo: selectedAssignment.moreInfo ?? '',
              }
            : {
                site: '',
                department: '',
                task: '',
                employee: '',
                moreInfo: '',
              }
        }
        onSubmit={async (values) => {
          const assignment = {
            //@ts-ignore
            userId: values.employee.length > 0 ? values.employee : null,
            //@ts-ignore
            siteId: values.site,
            taskId: values.task,
            departmentId: values.department,
            moreInfo: values.moreInfo,
          };
          if (selectedAssignment) {
            await updateAssignmentApi(assignment, selectedAssignment.id);
            setAssignJob(false);
            setSelectedAssignment(null);
          } else {
            await addAssignmentApi(assignment);
            setAssignJob(false);
            setSelectedAssignment(null);
          }
        }}
        onReset={() => {
          setSelectedAssignment(null);
          setAssignJob(false);
        }}
      >
        {({ handleChange, values, isSubmitting }) => (
          <Form>
            <DialogTitle id="form-dialog-title">{common.jobDialog.assignJob}</DialogTitle>
            <DialogContent>
              <div style={{ width: '60%' }}>
                <TextField
                  select
                  fullWidth
                  required
                  id="site"
                  name="site"
                  value={values.site}
                  label={common.jobDialog.chooseSite}
                  onChange={(e) => {
                    values.department = '';
                    values.task = '';
                    values.employee = '';
                    handleChange(e);
                  }}
                >
                  {sites?.map((site: any) => (
                    <MenuItem key={site.id} value={site.id}>
                      {site.name}
                    </MenuItem>
                  ))}
                </TextField>

                {/* @ts-ignore */}
                {values.site.length > 0 && (
                  <>
                    <DialogContentText classes={{ root: classes.MuiDialogContentText }}>
                      {common.jobDialog.department}
                    </DialogContentText>

                    <TextField
                      select
                      fullWidth
                      required
                      value={values.department}
                      id="department"
                      name="department"
                      label={common.jobDialog.chooseDepartment}
                      onChange={(e) => {
                        values.employee = '';
                        values.task = '';
                        handleChange(e);
                      }}
                    >
                      {sites
                        ?.find((s: any) => s.id === values.site)
                        .departmentTaskLists.map((department: any) => (
                          <MenuItem key={department.department.id} value={department.department.id}>
                            {department.department.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </>
                )}

                {values.department && (
                  <>
                    <DialogContentText classes={{ root: classes.MuiDialogContentText }}>
                      {common.jobDialog.task}
                    </DialogContentText>
                    <TextField
                      select
                      fullWidth
                      required
                      value={values.task}
                      id="task"
                      name="task"
                      label={common.jobDialog.chooseTask}
                      onChange={handleChange}
                    >
                      {sites
                        ?.find((s: any) => s.id === values.site)
                        .departmentTaskLists?.find(
                          (e: any) => e.department.id === values.department
                        )
                        ?.taskItems?.map((item: any) => (
                          <MenuItem key={item.task.id} value={item.task.id}>
                            {item.task.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </>
                )}

                {values.task && (
                  <>
                    <DialogContentText classes={{ root: classes.MuiDialogContentText }}>
                      {common.jobDialog.moreInfo}
                    </DialogContentText>
                    <TextField
                      fullWidth
                      id="moreInfo"
                      name="moreInfo"
                      value={values.moreInfo}
                      onChange={handleChange}
                      inputProps={{
                        maxLength: 500,
                      }}
                    ></TextField>

                    <DialogContentText classes={{ root: classes.MuiDialogContentText }}>
                      {common.jobDialog.employee}
                    </DialogContentText>
                    <TextField
                      select
                      fullWidth
                      id="employee"
                      name="employee"
                      value={values.employee}
                      label={common.jobDialog.choosePerson}
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        <em>{common.jobDialog.choosePerson}</em>
                      </MenuItem>
                      {employees?.map((option: any) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </>
                )}
              </div>
            </DialogContent>

            <DialogActions>
              <DialogButtons isSubmitting={isSubmitting} />
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default AssignJob;
