import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import common from '../strings/common.json';
import colors from '../strings/colors.json';
import { getInvoices } from '../api/invoices';
import { useHistory } from 'react-router-dom';
import { getMonthName } from '../components/helpers/getDate';
import getInvoiceStatus from '../components/helpers/getInvoiceStatus';
import { formatEuros } from '../components/helpers/getEuros';
import { getCompanyName } from '../components/helpers/getCompanyName';
import { createPdf } from '../Screens/InvoicePdf';
import '../style/layout/Report.css';

function Invoices() {
  const history = useHistory();
  const [invoices, setInvoices] = useState([]);

  const columns = [
    {
      title: common.billsTable.company,
      field: 'company',
      render: (rowData: any) => getCompanyName(rowData.company),
    },
    {
      title: common.billsTable.date,
      field: 'invoicingDate',
      render: (rowData: any) => getMonthName(rowData.invoicingDate, common),
    },
    {
      title: common.billsTable.sum,
      field: 'sum',
      render: (rowData: any) => formatEuros(rowData.sum),
    },
    {
      title: common.billsTable.status,
      field: 'approved',
      render: (rowData: any) => getInvoiceStatus(rowData.approved, common),
    },
  ];

  const openPDF = (rowData: any) => {
    if (rowData.reports.length > 0) {
      createPdf(rowData);
    }
  };

  const actions = [
    (rowData: any) => ({
      icon: () => <img src={require('../assets/PDF.svg').default} alt="pdf icon" />,
      tooltip: '',
      // @ts-ignore
      onClick: (event, rowData) => openPDF(rowData),
      hidden: !rowData.approved,
    }),
  ];

  useEffect(() => {
    const get = async () => {
      const response = await getInvoices();
      response.sort((a: any, b: any) => (a.invoicingDate < b.invoicingDate ? 1 : -1));
      setInvoices(response);
    };
    get();
  }, []);

  const onRowClick = (event: any, rowData: any) => {
    history.push(`/invoice/${rowData.id}`);
  };

  return (
    <div>
      <MaterialTable
        title=""
        localization={{
          header: {
            actions: '',
          },
        }}
        columns={columns}
        data={invoices}
        options={{
          headerStyle: { backgroundColor: colors.tableHeaderGrey },
          actionsColumnIndex: -1,
          pageSize: 10,
        }}
        actions={actions}
        onRowClick={(event: any, rowData: any) => onRowClick(event, rowData)}
      />
    </div>
  );
}

export default Invoices;
