import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { api } from './fetchUtils';

export const getValidAccessToken = async () => {
  const accessToken = localStorage.getItem('@accessToken');
  const refreshToken = localStorage.getItem('@refreshToken');

  if (!accessToken) {
    return null;
  }

  const decodedAccessToken = jwt_decode<{ exp: number }>(accessToken);
  const jwtExpirationDatetime = moment.unix(decodedAccessToken.exp);
  const currentDatetime = moment();
  const jwtExpired = jwtExpirationDatetime.isBefore(currentDatetime);

  if (jwtExpired) {
    try {
      const response = await api.post('/api/Authentication/refresh', {
        accessToken: accessToken,
        refreshToken: refreshToken,
      });
      localStorage.setItem('@accessToken', response.data.accessToken);
      localStorage.setItem('@refreshToken', response.data.refreshToken);
      return response.data.accessToken;
    } catch (error) {
      console.log('error', error);
      return null;
    }
  }

  return accessToken;
};

export const authenticate = async (email: string, password: string) => {
  localStorage.removeItem('@accessToken');
  localStorage.removeItem('@refreshToken');

  try {
    const response = await api.post('/api/Authentication/admin', {
      email: email,
      password: password,
    });
    return response;
  } catch (error) {
    console.log('error', error);
    return null;
  }
};
