import React from 'react';
import { Container } from 'reactstrap';
import TopBar from './components/layout/TopBar';
import MainMenu from './navigation/MainMenu';

const Layout = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
  return (
    <>
      <MainMenu />
      <TopBar />
      <Container fluid className="main-container">
        {children}
      </Container>
    </>
  );
};

export default Layout;
