import { makeStyles, createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import common from '../../strings/common.json';

interface DialogButtonsProps {
  isSubmitting?: Boolean;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  })
);
const SubmitButton = withStyles((theme: Theme) => ({
  root: {
    color: 'white',
    backgroundColor: '#7DA0CD',
    '&:hover': {
      backgroundColor: '#525d6b',
    },
  },
}))(Button);
const CancelButton = withStyles((theme: Theme) => ({
  root: {
    color: 'grey',
    backgroundColor: '#DEDEDE',
    '&:hover': {
      backgroundColor: '#a8a8a8',
    },
  },
}))(Button);

function DialogButtons({ isSubmitting }: DialogButtonsProps) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {/* @ts-ignore */}
      <CancelButton type="reset" variant="contained" disabled={isSubmitting}>
        {common.buttonLabels.cancel}
      </CancelButton>
      {/* @ts-ignore */}
      <SubmitButton type="submit" variant="contained" disabled={isSubmitting}>
        {common.buttonLabels.save}
      </SubmitButton>
    </div>
  );
}

export default DialogButtons;
