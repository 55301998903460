import { api } from './fetchUtils';

export const getDepartmentApi = async () => {
  try {
    const response = await api.get('/api/Departments');
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    console.log('Error: ', err);
    return [];
  }
};

export const addDepartmentApi = async (department: string) => {
  try {
    const response = await api.post('/api/Departments', { name: department });
    if (response.status === 200) {
      return response.status;
    }
  } catch (err) {
    console.log('Error: ', err);
    return null;
  }
};

export const updateDepartmentApi = async (name: string, id: string) => {
  try {
    const response = await api.put(`/api/Departments/${id}`, { name: name });
    if (response.status === 200) {
      return response.status;
    }
  } catch (err) {
    console.log('Error: ', err);
    return null;
  }
};

export const deleteDepartmentApi = async (id: string) => {
  try {
    const response = await api.delete(`/api/Departments/${id}`);
    if (response.status === 200) {
      return response.status;
    }
  } catch (err) {
    console.log('Error: ', err);
    return null;
  }
};
