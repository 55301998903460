import { api } from './fetchUtils';

export const getCompaniesApi = async () => {
  try {
    const response = await api.get('/api/Companies');
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    console.log('Error: ', err);
    return [];
  }
};

export const addCompanyApi = async (company: any) => {
  try {
    const response = await api.post('/api/Companies', company);
    if (response.status === 200) {
      return response.status;
    }
  } catch (err) {
    console.log('Error: ', err);
    return null;
  }
};

export const updateCompanyApi = async (company: any, id: string) => {
  try {
    const response = await api.put(`/api/Companies/${id}`, company);
    if (response.status === 200) {
      return response.status;
    }
  } catch (err) {
    console.log('Error: ', err);
    return null;
  }
};

export const deleteCompanyApi = async (id: string) => {
  try {
    const response = await api.delete(`/api/Companies/${id}`);
    if (response.status === 200) {
      return response.status;
    }
  } catch (err) {
    console.log('Error: ', err);
    return null;
  }
};
