import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Formik, Form } from 'formik';
import DialogButtons from '../../components/layout/DialogButtons';
import { addDepartmentApi } from '../../api/departments';
import common from '../../strings/common.json';
import { resetValidity, setEmptyValidityMessage } from '../../components/helpers/emptyValidation';

interface AddDepartmentProps {
  open: boolean;
  setOpen: any;
  originalValue?: any;
  handleSubmit?: any;
}

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    paddingBottom: '5vh',
  },
  MuiDialogContentText: {
    fontSize: 18,
    color: 'black',
    paddingTop: 20,
  },
}));

function Department({ open, setOpen, handleSubmit, originalValue }: AddDepartmentProps) {
  const classes = useStyles();

  return (
    <Dialog fullWidth={true} maxWidth="sm" classes={{ paper: classes.dialogPaper }} open={open}>
      <Formik
        initialValues={{
          department: originalValue ? originalValue.name : '',
        }}
        onSubmit={async (values, { resetForm }) => {
          if (handleSubmit) {
            await handleSubmit(values);
          } else {
            const result = await addDepartmentApi(values.department);
            if (result !== 200) {
              window.alert(common.departmentDialog.errorAdd);
            }
          }
          resetForm({});
          setOpen(false);
        }}
        onReset={() => {
          setOpen(false);
        }}
      >
        {({ values, handleChange }) => (
          <Form>
            <DialogTitle id="form-dialog-title">
              {originalValue ? common.departmentDialog.edit : common.departmentDialog.newDepartment}
            </DialogTitle>
            <DialogContent>
              <div style={{ width: '40%' }}>
                <TextField
                  fullWidth
                  required
                  id="department"
                  name="department"
                  onInput={(e) => resetValidity(e)}
                  onInvalid={(e) => setEmptyValidityMessage(e)}
                  label={common.departmentDialog.departmentName}
                  value={values.department}
                  onChange={handleChange}
                ></TextField>
              </div>
            </DialogContent>

            <DialogActions>
              <DialogButtons />
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default Department;
