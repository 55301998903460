import { api } from './fetchUtils';

export const getInvoices = async () => {
  try {
    const response = await api.get('/api/Invoices');
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    console.log('Error: ', err);
    return [];
  }
};

export const getInvoice = async (id: string) => {
  try {
    const response = await api.get(`/api/Invoices/${id}`);
    return response.data;
  } catch (err) {
    console.log('Error: ', err);
    return null;
  }
};

export const approveInvoice = async (id: string) => {
  try {
    const response = await api.patch(`/api/Invoices/Approve/${id}`, {
      approved: true,
    });
    return response.status;
  } catch (err) {
    console.log('Error: ', err);
    return null;
  }
};
