import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { deleteMachine, getMachines, getMachineTypes } from '../../api/machines';
import Machine from '../Dialogs/Machine';
import common from '../../strings/common.json';
import LoadIndicator from '../../components/LoadIndicator';

const Machines = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (value: any) => void;
}): JSX.Element => {
  const [machines, setMachines] = useState([]);
  const [machineTypes, setMachineTypes] = useState([]);
  const [selectedMachine, setSelectedMachine] = useState();
  const [refreshResults, setRefreshResults] = useState(true);
  const [loading, setLoading] = useState(false);

  const actions = [
    {
      icon: 'edit',
      tooltip: common.machineDialog.edit,
      // @ts-ignore
      onClick: (e: any, rowData: any) => selectMachine(e, rowData),
    },
    {
      icon: 'delete',
      tooltip: common.machineDialog.delete,
      // @ts-ignore
      onClick: (e: any, rowData: any) => deleteSelectedMachine(e, rowData),
    },
  ];

  const selectMachine = (e: any, rowData: any) => {
    setSelectedMachine(rowData);
    setOpen(true);
  };

  useEffect(() => {
    const getTypes = async () => {
      setLoading(true);
      const response = await getMachineTypes();
      setMachineTypes(response);
      setLoading(false);
    };
    const get = async () => {
      setLoading(true);
      const response = await getMachines();
      setMachines(response);
      setLoading(false);
    };
    if (refreshResults) {
      getTypes();
      get();
      setRefreshResults(false);
    }
  }, [refreshResults]);

  const deleteSelectedMachine = async (e: any, rowData: any) => {
    if (window.confirm(common.machineDialog.confirmDelete)) {
      const response = await deleteMachine(rowData.id);
      if (response !== 200) {
        window.alert(common.machineDialog.errorDelete);
        return;
      }
      setRefreshResults(true);
    }
  };

  const getMachineTypeName = (rowData: any) => {
    const type: any = machineTypes.find((type: any) => type.id === rowData.machineTypeId);
    if (type !== undefined) {
      return type.name;
    }
    return '';
  };
  if (loading) {
    return <LoadIndicator />;
  }
  return (
    <>
      <MaterialTable
        title=""
        localization={{
          header: {
            actions: '',
          },
        }}
        columns={[
          {
            field: 'number',
          },
          {
            field: 'machineTypeId',
            render: (rowData) => getMachineTypeName(rowData),
          },
        ]}
        data={machines}
        actions={actions}
        options={{
          actionsColumnIndex: -1,
          sorting: false,
          draggable: false,
          toolbar: false,
          header: false,
          paging: false,
        }}
      />
      <Machine
        open={open}
        setOpen={setOpen}
        machineTypes={machineTypes}
        selectedMachine={selectedMachine}
        refreshPage={() => setRefreshResults(true)}
        setSelectedMachine={setSelectedMachine}
      />
    </>
  );
};

export default Machines;
