import { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import common from '../../strings/common.json';
import '../../style/layout/TopBar.css';
import { DialogContext } from '../context';
import { approveInvoice, getInvoice } from '../../api/invoices';
import { getMonthName } from '../helpers/getDate';

const TopBar = (): JSX.Element => {
  //@ts-ignore
  const { setAssignJob } = useContext(DialogContext);
  const { setAddEmployee } = useContext(DialogContext);
  const { setExportExcel } = useContext(DialogContext);
  const location = useLocation();
  const [invoice, setInvoice] = useState<any>();
  const [clickedApproved, setClickedApproved] = useState<boolean>(false);
  const path = location.pathname.startsWith('/invoice/') ? '/invoice' : location.pathname;
  
  useEffect(() => {
    if (path === '/invoice') {
      getSelectedInvoice();
    }
    return () => {
      setInvoice(undefined);
    };
  }, [path, clickedApproved]);

  const getSelectedInvoice = async () => {
    const response: any = await getInvoice(location.pathname.split('/invoice/')[1]);
    if (response !== null) {
      setInvoice(response);
    }
  };

  const getTitle = () => {
    const addNewJob = () => {
      setAssignJob(true);
    };

    const exportExcel = () => {
      setExportExcel(true);
    };

    const addNewEmployee = () => {
      setAddEmployee(true);
    };

    const acceptInvoice = async () => {
      if (window.confirm(common.approveInvoiceDialog.confirmApprove)) {
        const result = await approveInvoice(location.pathname.split('/invoice/')[1]);
        if (result !== 200) {
          window.alert(common.approveInvoiceDialog.errorApprove);
        } else {
          window.alert(common.approveInvoiceDialog.approved);
          setClickedApproved(true);
        }
      }
    };

    switch (path) {
      case '/assign':
        return (
          <>
            <h5> {common.menu.assign} </h5>
            <h6 style={{ cursor: 'pointer' }} onClick={() => addNewJob()}>
              {common.addingOptions.addJob}
            </h6>
          </>
        );
      case '/reports':
        return (
          <>
            <h5>{common.menu.reports}</h5>
            <h6 style={{ cursor: 'pointer' }} onClick={() => exportExcel()}>
              {common.addingOptions.exportExcel}
            </h6>
          </>
        );
      case '/invoice':
        return (
          <>
            <h5>
              {invoice
                ? `${invoice.company.name}, ${getMonthName(invoice.invoicingDate, common)}`
                : ''}
            </h5>

            {invoice && !invoice.approved ? (
              <h6 style={{ cursor: 'pointer' }} onClick={() => acceptInvoice()}>
                {common.invoiceStatus.acceptInvoice.toLocaleUpperCase()}
              </h6>
            ) : null}
          </>
        );
      case '/invoices':
        return <h5>{common.menu.bills}</h5>;
      case '/employees':
        return (
          <>
            <h5> {common.menu.users} </h5>
            <h6 style={{ cursor: 'pointer' }} onClick={() => addNewEmployee()}>
              {common.addingOptions.addEmployee}
            </h6>
          </>
        );
      case '/settings':
        return <h5>{common.menu.settings}</h5>;
      case '/logout':
        return <h5>{common.menu.logout}</h5>;
      default:
        return '';
    }
  };
  return <div className="top-bar-container">{getTitle()}</div>;
};

export default TopBar;
