import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Formik, Form } from 'formik';
import DialogButtons from '../../components/layout/DialogButtons';
import common from '../../strings/common.json';
import { MenuItem } from '@material-ui/core';
import { getMachines } from '../../api/machines';
import { Select, InputLabel, FormControl } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { getSites } from '../../api/sites';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import fi from 'date-fns/locale/fi';
import 'react-datepicker/dist/react-datepicker.css';
import { getHoursAndMinutesArray, getMinutes } from '../../components/helpers/getWorkedTime';
import { updateReport } from '../../api/reports';
import '../../style/layout/Forms.css';
import { resetValidity, setEmptyValidityMessage } from '../../components/helpers/emptyValidation';
import isNumber from '../../components/helpers/isNumber';
import numericStringToFloat from '../../components/helpers/numericStringToFloat';
import replaceFromDotToComma from '../../components/helpers/replaceFromDotToComma';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    paddingBottom: '5vh',
  },
  MuiDialogContentText: {
    fontSize: 18,
    color: 'black',
    paddingTop: 20,
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(0),
  },
}));

const Report = ({
  open,
  setOpen,
  selectedReport,
  refreshPage,
  setSelectedReport,
}: {
  open: boolean;
  setOpen: (value: any) => void;
  selectedReport: any;
  refreshPage: () => void;
  setSelectedReport: (value: any) => void;
}): JSX.Element => {
  const classes = useStyles();
  const [sites, setSites] = useState([]);
  const [site, setSite] = useState('');
  const [departmentTaskLists, setDepartmentTaskLists] = useState([]);
  const [department, setDepartment] = useState('');
  const [taskItems, setTaskItems] = useState([]);
  const [task, setTask] = useState('');
  const [machines, setMachines] = useState([]);
  const [machine, setMachine] = useState('');
  const [date, setDate] = useState<any>(new Date());
  const [hoursAndMinutes, setHoursAndMinutes] = useState<number[]>([]);

  registerLocale('fi', fi);

  useEffect(() => {
    const get = async () => {
      const siteList = await getSites();
      setSites(siteList);
      const machineList = await getMachines();
      setMachines(machineList);
    };
    get();
  }, []);

  useEffect(() => {
    const get = async () => {
      const pickedSite: any = sites.find((s: any) => s.name === site);
      if (pickedSite !== undefined) {
        setDepartmentTaskLists(pickedSite.departmentTaskLists);
      }
    };
    if (site !== '') {
      get();
    }
  }, [site, sites]);

  useEffect(() => {
    const get = async () => {
      const pickedDepartment: any = departmentTaskLists.find(
        (dep: any) => dep.department.name === department
      );
      if (pickedDepartment !== undefined) {
        setTaskItems(pickedDepartment.taskItems);
      }
    };
    if (departmentTaskLists.length !== 0) {
      get();
    }
  }, [departmentTaskLists, department]);

  useEffect(() => {
    if (selectedReport !== undefined) {
      setSite(selectedReport.siteName);
      setDepartment(selectedReport.departmentName);
      setTask(selectedReport.taskName);
      setMachine(selectedReport.machineNumber || '');
      setDate(new Date(selectedReport.completedDate));
      setHoursAndMinutes(getHoursAndMinutesArray(selectedReport.workedMinutes));
    }
  }, [selectedReport]);

  const submitUpdateReport = async (
    km: any,
    minutes: number,
    calculatedCost: number,
    comments: string,
    moreInfo: string
  ) => {
    const values = {
      workedMinutes: minutes,
      departmentName: department,
      siteName: site,
      taskName: task,
      machineNumber: machine,
      distance: km !== '' ? km : 0,
      completedDate: date,
      calculatedCost: calculatedCost,
      comments: comments,
      moreInfo: moreInfo,
    };
    const response = await updateReport(values, selectedReport.id);
    if (response !== 200) {
      window.alert(common.reportsDialog.errorEdit);
      return;
    }
    refreshPage();
  };

  return (
    <Dialog fullWidth={true} maxWidth="sm" classes={{ paper: classes.dialogPaper }} open={open}>
      <Formik
        initialValues={{
          km: selectedReport ? selectedReport.distance : '0',
          hours: hoursAndMinutes !== [] ? hoursAndMinutes[0] : '0',
          minutes: hoursAndMinutes !== [] ? hoursAndMinutes[1] : '0',
          calculatedCost: selectedReport
            ? replaceFromDotToComma(selectedReport.calculatedCost)
            : '0',
          comments: selectedReport ? selectedReport.comments : '',
          moreInfo: selectedReport ? selectedReport.moreInfo : '',
        }}
        onSubmit={async (values, { resetForm }) => {
          if (isNumber(values.calculatedCost)) {
            const minutes = getMinutes(
              values.hours !== '' ? values.hours : 0,
              values.minutes !== '' ? values.minutes : 0
            );
            submitUpdateReport(
              values.km,
              minutes,
              numericStringToFloat(values.calculatedCost),
              values.comments,
              values.moreInfo
            );
            resetForm({});
            setOpen(false);
            setSelectedReport(undefined);
          }
        }}
        onReset={() => {
          setOpen(false);
          setSelectedReport(undefined);
        }}
      >
        {({ values, handleChange }) => (
          <Form>
            <DialogTitle id="form-dialog-title">{common.reportsDialog.edit}</DialogTitle>
            <DialogContent>
              <div style={{ width: '70%' }}>
                <FormControl required className={classes.formControl}>
                  <InputLabel id="sites">{common.reportsTable.site}</InputLabel>
                  <Select
                    labelId="sites"
                    id="sites"
                    value={site}
                    onChange={(e: any) => setSite(e.target.value)}
                    className={classes.selectEmpty}
                  >
                    {sites.map((option: any) => (
                      <MenuItem key={option.name} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl required className={classes.formControl}>
                  <InputLabel id="departments">{common.reportsTable.department}</InputLabel>
                  <Select
                    labelId="departments"
                    id="departments"
                    value={department}
                    onChange={(e: any) => setDepartment(e.target.value)}
                    className={classes.selectEmpty}
                  >
                    {departmentTaskLists.map((option: any) => (
                      <MenuItem key={option.department.name} value={option.department.name}>
                        {option.department.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl required className={classes.formControl}>
                  <InputLabel id="tasks">{common.reportsTable.task}</InputLabel>
                  <Select
                    labelId="tasks"
                    id="tasks"
                    value={task}
                    onChange={(e: any) => setTask(e.target.value)}
                    className={classes.selectEmpty}
                  >
                    {taskItems.map((option: any) => (
                      <MenuItem key={option.task.name} value={option.task.name}>
                        {option.task.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl required className={classes.formControl}>
                  <InputLabel id="machines" required={false}>
                    {common.reportsTable.machine}
                  </InputLabel>
                  <Select
                    required={false}
                    labelId="machines"
                    id="machines"
                    value={machine}
                    onChange={(e: any) => setMachine(e.target.value)}
                    className={classes.selectEmpty}
                  >
                    {machines.map((option: any) => (
                      <MenuItem key={option.number} value={option.number}>
                        {option.number}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  fullWidth
                  required
                  onInput={(e) => resetValidity(e)}
                  onInvalid={(e) => setEmptyValidityMessage(e)}
                  id="calculatedCost"
                  name="calculatedCost"
                  label={common.reportsTable.cost}
                  value={values.calculatedCost}
                  onChange={handleChange}
                ></TextField>

                <TextField
                  id="hours"
                  name="hours"
                  label={common.reportsDialog.hours}
                  value={values.hours}
                  onChange={handleChange}
                  className="time-fields"
                ></TextField>
                <TextField
                  className="minutes-field time-fields"
                  id="minutes"
                  name="minutes"
                  label={common.reportsDialog.minutes}
                  value={values.minutes}
                  onChange={handleChange}
                ></TextField>

                <TextField
                  fullWidth
                  onInvalid={(e) => setEmptyValidityMessage(e)}
                  id="km"
                  name="km"
                  label={common.reportsTable.drivenKilometers}
                  value={values.km}
                  onChange={handleChange}
                ></TextField>

                <label>{common.reportsTable.date}</label>
                <DatePicker
                  className="date-picker"
                  selected={date}
                  onChange={(date: any) => setDate(date)}
                  locale="fi"
                  dateFormat="dd.MM.yyyy"
                />

                <TextField
                  fullWidth
                  id="moreInfo"
                  name="moreInfo"
                  label={common.reportsTable.moreInfo}
                  value={values.moreInfo}
                  onChange={handleChange}
                ></TextField>

                <TextField
                  fullWidth
                  id="comments"
                  name="comments"
                  label={common.reportsTable.comments}
                  value={values.comments}
                  onChange={handleChange}
                ></TextField>
              </div>
            </DialogContent>

            <DialogActions>
              <DialogButtons />
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default Report;
