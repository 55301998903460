import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import { Formik, Form } from 'formik';
import { Checkbox, DialogContentText } from '@material-ui/core';
import common from '../../strings/common.json';
import { useEffect, useState } from 'react';
import { FormControlLabel } from '@material-ui/core';
import DepartmentTask from './DepartmentTask';
import DialogButtonsSite from '../../components/layout/DialogButtonsSite';
import { addSite, updateSite } from '../../api/sites';
import { resetValidity, setEmptyValidityMessage } from '../../components/helpers/emptyValidation';
import isNumber from '../../components/helpers/isNumber';
import numericStringToFloat from '../../components/helpers/numericStringToFloat';
import replaceFromDotToComma from '../../components/helpers/replaceFromDotToComma';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    paddingBottom: '5vh',
  },
  MuiDialogContentText: {
    fontSize: 18,
    color: 'black',
    paddingTop: 20,
  },
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
}));

const Site = ({
  open,
  setOpen,
  refreshPage,
  selectedSite,
  setSelectedSite,
  departments,
}: {
  open: boolean;
  setOpen: (value: any) => void;
  refreshPage: () => void;
  selectedSite: any;
  setSelectedSite: (value: any) => void;
  departments: any[];
}): JSX.Element => {
  const classes = useStyles();
  const [departmentBoxes, setDepartmentBoxes] = useState<
    { id: string; name: string; value: boolean }[]
  >([]);
  const [departmentTaskLists, setDepartmentTaskLists] = useState<
    { departmentId: string; name: string; taskIds: string[] }[]
  >([]);
  const [name, setName] = useState('');
  const [pricePerKm, setPricePerKm] = useState(0);
  const [costCenter, setCostCenter] = useState('');
  const [tasksVisibility, setTasksVisibility] = useState(false);

  const [alueKeraysPiste, setAlueKeraysPiste] = useState<number>(50);
  const [ekopiste, setEkopiste] = useState<number>(50);

  const min = 0;
  const max = 100;

  useEffect(() => {
    if (departments !== undefined && departments.length) {
      const departmentsTemp: { id: string; name: string; value: boolean }[] = [];
      departments.forEach((option) => {
        let included: any = undefined;
        if (selectedSite !== undefined) {
          included = selectedSite.departmentTaskLists.find(
            (element: any) => element.department.id === option.id
          );
          if (
            selectedSite.firstSplitCostPercentage + selectedSite.secondSplitCostPercentage ===
            100
          ) {
            setAlueKeraysPiste(selectedSite?.firstSplitCostPercentage);
            setEkopiste(selectedSite?.secondSplitCostPercentage);
          } else {
            setAlueKeraysPiste(50);
            setEkopiste(50);
          }
        }
        departmentsTemp.push({
          id: option.id,
          name: option.name,
          value: included !== undefined ? true : false,
        });
      });
      setDepartmentBoxes(departmentsTemp);
    }
  }, [departments, selectedSite]);

  const handleDepartments = (e: any) => {
    const departmentBoxesTemp = [...departmentBoxes];
    for (let index = 0; index < departmentBoxes.length; index++) {
      if (departmentBoxes[index].id === e.target.name) {
        departmentBoxesTemp[index].value = e.target.checked;
      }
    }
    setDepartmentBoxes(departmentBoxesTemp);
  };
  const getTaskIds = (departmentId: string) => {
    const taskIds: string[] = [];
    if (selectedSite !== undefined) {
      const included = selectedSite.departmentTaskLists.find(
        (element: any) => element.department.id === departmentId
      );
      if (included !== undefined) {
        included.taskItems.map((taskItem: any) => taskIds.push(taskItem.task.id));
      }
    }
    return taskIds;
  };

  const submitDepartments = (values: any) => {
    setName(values.name);
    setPricePerKm(values.pricePerKm === null ? 0 : values.pricePerKm);
    setCostCenter(values.costCenter);
    const departmentTasksTemp: { departmentId: string; name: string; taskIds: string[] }[] = [];
    for (let index = 0; index < departmentBoxes.length; index++) {
      if (departmentBoxes[index].value) {
        departmentTasksTemp.push({
          departmentId: departmentBoxes[index].id,
          name: departmentBoxes[index].name,
          taskIds: getTaskIds(departmentBoxes[index].id),
        });
      }
    }
    setDepartmentTaskLists(departmentTasksTemp);
    setTasksVisibility(true);
  };

  const reset = () => {
    setOpen(false);
    setSelectedSite(undefined);
    setDepartmentTaskLists([]);
    setTasksVisibility(false);
  };

  const submitSite = (e: any) => {
    e.preventDefault();

    const values = {
      name: name,
      pricePerKm: pricePerKm,
      costCenter: costCenter,
      departmentTaskLists: departmentTaskLists,
    };

    if (departmentBoxes.find((dep) => dep.id === common.splitDepartmentId && dep.value === true)) {
      //@ts-ignore
      values.firstSplitCostPercentage = alueKeraysPiste;
      //@ts-ignore
      values.secondSplitCostPercentage = ekopiste;
    }

    selectedSite !== undefined ? update(values) : add(values);
    reset();
  };

  const add = async (values: {}) => {
    const response = await addSite(values);
    if (response !== 200) {
      window.alert(common.siteDialog.errorAdd);
      return;
    }
    refreshPage();
  };

  const update = async (values: {}) => {
    const response = await updateSite(values, selectedSite.id);
    if (response !== 200) {
      window.alert(common.siteDialog.errorEdit);
      return;
    }
    refreshPage();
  };

  return (
    <Dialog fullWidth={true} maxWidth="sm" classes={{ paper: classes.dialogPaper }} open={open}>
      {tasksVisibility ? null : (
        <Formik
          initialValues={{
            name: selectedSite ? selectedSite.name : '',
            pricePerKm: selectedSite ? replaceFromDotToComma(selectedSite.pricePerKm) : '',
            costCenter: selectedSite?.costCenter ? selectedSite.costCenter : '',
          }}
          onSubmit={async (values) => {
            if (isNumber(values.pricePerKm) && values.name !== '') {
              const depValues = {
                name: values.name,
                pricePerKm: numericStringToFloat(values.pricePerKm),
                costCenter: values.costCenter,
              };
              submitDepartments(depValues);
            }
          }}
          onReset={() => {
            departmentBoxes.forEach((dep) => (dep.value = false));
            setAlueKeraysPiste(50);
            setEkopiste(50);
            reset();
          }}
        >
          {({ values, handleChange }) => (
            <Form>
              <DialogTitle id="form-dialog-title">
                {selectedSite ? common.siteDialog.edit : common.siteDialog.newSite}
              </DialogTitle>
              <DialogContent>
                <div style={{ width: '40%' }}>
                  <TextField
                    fullWidth
                    required
                    onInput={(e) => resetValidity(e)}
                    onInvalid={(e) => setEmptyValidityMessage(e)}
                    id="name"
                    name="name"
                    label={common.siteDialog.siteName}
                    value={values.name}
                    onChange={handleChange}
                  ></TextField>
                  <TextField
                    className={classes.input}
                    fullWidth
                    required
                    onInput={(e) => resetValidity(e)}
                    onInvalid={(e) => setEmptyValidityMessage(e)}
                    id="pricePerKm"
                    name="pricePerKm"
                    label={common.siteDialog.kilometerPrice}
                    value={values.pricePerKm}
                    onChange={handleChange}
                  ></TextField>
                  <TextField
                    fullWidth
                    required
                    onInput={e => resetValidity(e)}
                    onInvalid={e => setEmptyValidityMessage(e)}
                    id="costCenter"
                    name="costCenter"
                    label={common.siteDialog.costCenter}
                    value={values.costCenter}
                    onChange={e => {
                      if (e.target.value === '' || isNumber(e.target.value)) {
                        handleChange(e)
                      }
                    }}
                  ></TextField>
                </div>

                <DialogContentText classes={{ root: classes.MuiDialogContentText }}>
                  {common.siteDialog.departments}
                </DialogContentText>
                <FormGroup row={true}>
                  {departmentBoxes.map((option) => (
                    <FormControlLabel
                      key={option.id}
                      control={
                        <Checkbox
                          checked={option.value}
                          onChange={handleDepartments}
                          name={option.id}
                        />
                      }
                      label={option.name}
                    />
                  ))}
                </FormGroup>
                {departmentBoxes.find(
                  (dep) => dep.id === common.splitDepartmentId && dep.value === true
                ) ? (
                  <div style={{ display: 'flex', justifyContent: 'left' }}>
                    <TextField
                      id="alueKeraysPiste"
                      name="alueKeraysPiste"
                      required
                      onInput={(e) => resetValidity(e)}
                      onInvalid={(e) => setEmptyValidityMessage(e)}
                      className={classes.input}
                      style={{ width: '40%', marginRight: '2rem' }}
                      type="number"
                      value={alueKeraysPiste}
                      inputProps={{ min, max }}
                      label={common.siteDialog.splittedDepartment1}
                      onChange={(e) => {
                        var value = parseInt(e.target.value, 10);
                        if (value > max) value = max;
                        if (value < min) value = min;
                        setAlueKeraysPiste(value);
                        setEkopiste(100 - value);
                      }}
                    />
                    <TextField
                      id="ekopiste"
                      name="ekopiste"
                      required
                      onInput={(e) => resetValidity(e)}
                      onInvalid={(e) => setEmptyValidityMessage(e)}
                      className={classes.input}
                      style={{ width: '40%' }}
                      type="number"
                      value={ekopiste}
                      inputProps={{ min, max }}
                      label={common.siteDialog.splittedDepartment2}
                      onChange={(e) => {
                        var value = parseInt(e.target.value, 10);
                        if (value > max) value = max;
                        if (value < min) value = min;
                        setEkopiste(value);
                        setAlueKeraysPiste(100 - value);
                      }}
                    />
                  </div>
                ) : null}
              </DialogContent>

              <DialogActions>
                <DialogButtonsSite />
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
      {tasksVisibility ? (
        <DepartmentTask
          departmentTaskLists={departmentTaskLists}
          setDepartmentTaskLists={setDepartmentTaskLists}
          addSite={(e: any) => submitSite(e)}
          reset={reset}
        />
      ) : null}
    </Dialog>
  );
};

export default Site;
