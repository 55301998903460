import { api } from './fetchUtils';

export const getAssignmentApi = async (assignmentId: string) => {
  try {
    const response = await api.get(`/api/Assignments/${assignmentId}`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    console.log('Error: ', err);
    return [];
  }
};

export const getAssignmentsApi = async () => {
  try {
    const response = await api.get('/api/Assignments/all');
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    console.log('Error: ', err);
    return [];
  }
};

export const getAssignmentsSearchApi = async (
  assignmentRangeOffset: Number,
  assignmentRange: Number,
  assignmentSearchQuery: string
) => {
  try {
    const response = await api.get(
      `/api/Assignments/search?assignmentRangeOffset=${assignmentRangeOffset}
        &assignmentRange=${assignmentRange}
        &assignmentSearchQuery=${assignmentSearchQuery}`
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    console.log('Error: ', err);
    return [];
  }
};

export const addAssignmentApi = async (assignment: any) => {
  try {
    const response = await api.post('/api/Assignments', assignment);
    if (response.status === 200) {
      return response.status;
    }
  } catch (err) {
    console.log('Error: ', err);
    return null;
  }
};

export const updateAssignmentApi = async (assignment: any, id: string) => {
  try {
    const response = await api.put(`/api/Assignments/${id}`, assignment);
    if (response.status === 200) {
      return response.status;
    }
  } catch (err) {
    console.log('Error: ', err);
    return null;
  }
};

export const deleteAssignmentApi = async (id: string) => {
  try {
    const response = await api.delete(`/api/assignments/${id}`);
    if (response.status === 200) {
      return response.status;
    }
  } catch (err) {
    console.log('Error: ', err);
    return null;
  }
};
