import { PDFDocument, PDFPage, rgb } from 'pdf-lib';
import { getMonthName } from '../components/helpers/getDate';
import { getRoundedValue } from '../components/helpers/getRoundedValue';
import { formatToHoursAndMinutes } from '../components/helpers/getWorkedTime';
import common from '../strings/common.json';
import replaceFromDotToComma from '../components/helpers/replaceFromDotToComma';
// Get the form so we can add fields to it

export const createPdf = async (invoiceData: any) => {
  const sum = invoiceData.sum;
  const reports = invoiceData.reports as [any];

  // static and pre-filled values
  const companyName = 'Puhas Oy';
  const companyStreetAddress = 'Linnunlahdentie 2, rakennus 4 A, 1. kerros (Tiedepuisto)';
  const companyCityAddress = '80110 Joensuu';
  const lasku = 'LASKU';
  const taxRateValue = '24,00';
  const paymentTermPreValue = '14 pv netto';
  const noteTimePreValue = '8 pv';
  const latePaymentIntPreValue = '5 %';

  // constants data
  const taxRate = 0.24;

  // calculations
  const roundedSum = getRoundedValue(sum);
  const totalSumWithTax = getRoundedValue(sum + taxRate * sum);

  // constants related to pdf layout
  const pageHeight = 750;
  const pageWidth = 550;
  const pagePadding = 30;

  const headerTableX = 250;
  const headerTableY = 570;
  const headerTableHeight = 120;
  const headerTableWidth = 240;
  const rowHeight = 30;
  const paddingHorizontal = 7;
  const paddingVertical = 2;

  // texts related
  const bodyTextSize = 8;
  const tableFontSize = 8;
  const textFieldHeight = 18;
  const formFont = 10;

  // colors
  const white = rgb(1, 1, 1);
  const gray = rgb(0.9, 0.9, 0.9);

  let pdfDoc = await PDFDocument.create();
  const page = pdfDoc.addPage([pageWidth, pageHeight]);
  const form = pdfDoc.getForm();

  page.drawText(companyName, { x: pagePadding, y: 700, size: bodyTextSize });
  page.drawText(companyStreetAddress, {
    x: pagePadding,
    y: 680,
    size: bodyTextSize,
  });
  page.drawText(companyCityAddress, { x: pagePadding, y: 660, size: bodyTextSize });
  page.drawText(lasku, { x: headerTableX, y: 700, size: bodyTextSize });

  // *** Table ***
  page.drawRectangle({
    x: headerTableX,
    y: headerTableY,
    width: headerTableWidth,
    height: headerTableHeight,
    borderWidth: 0.5,
    color: white,
  });

  //Horizontal lines
  page.drawLine({
    start: { x: headerTableX, y: headerTableY + rowHeight * 3 },
    end: { x: headerTableX + headerTableWidth, y: headerTableY + rowHeight * 3 },
    thickness: 0.5,
  });

  page.drawLine({
    start: { x: headerTableX, y: headerTableY + rowHeight * 2 },
    end: { x: headerTableX + headerTableWidth, y: headerTableY + rowHeight * 2 },
    thickness: 0.5,
  });

  page.drawLine({
    start: { x: headerTableX, y: headerTableY + rowHeight },
    end: { x: headerTableX + headerTableWidth, y: headerTableY + rowHeight },
    thickness: 0.5,
  });

  //Vertical lines
  page.drawLine({
    start: { x: headerTableX + headerTableWidth / 2, y: headerTableY + rowHeight },
    end: { x: headerTableX + headerTableWidth / 2, y: headerTableY + rowHeight * 3 },
    thickness: 0.5,
  });

  page.drawLine({
    start: { x: headerTableX + headerTableWidth / 3, y: headerTableY + rowHeight * 3 },
    end: { x: headerTableX + headerTableWidth / 3, y: headerTableY + rowHeight * 4 },
    thickness: 0.5,
  });

  page.drawLine({
    start: { x: headerTableX + (2 * headerTableWidth) / 3, y: headerTableY + rowHeight * 3 },
    end: { x: headerTableX + (2 * headerTableWidth) / 3, y: headerTableY + rowHeight * 4 },
    thickness: 0.5,
  });

  // Table content
  page.drawText(common.invoicePdf.dateOfInvoice, {
    x: headerTableX + paddingVertical,
    y: headerTableY + rowHeight * 4 - paddingHorizontal,
    size: tableFontSize,
  });
  page.drawText(common.invoicePdf.invoiceNumber, {
    x: headerTableX + headerTableWidth / 3 + paddingVertical,
    y: headerTableY + rowHeight * 4 - paddingHorizontal,
    size: tableFontSize,
  });
  page.drawText(common.invoicePdf.customerNumber, {
    x: headerTableX + (2 * headerTableWidth) / 3 + paddingVertical,
    y: headerTableY + rowHeight * 4 - paddingHorizontal,
    size: tableFontSize,
  });
  page.drawText(common.invoicePdf.dueDate, {
    x: headerTableX + paddingVertical,
    y: headerTableY + rowHeight * 3 - paddingHorizontal,
    size: tableFontSize,
  });
  page.drawText(common.invoicePdf.paymentTerm, {
    x: headerTableX + headerTableWidth / 2 + paddingVertical,
    y: headerTableY + rowHeight * 3 - paddingHorizontal,
    size: tableFontSize,
  });
  page.drawText(common.invoicePdf.noteTime, {
    x: headerTableX + paddingVertical,
    y: headerTableY + rowHeight * 2 - paddingHorizontal,
    size: tableFontSize,
  });
  page.drawText(common.invoicePdf.latePaymentInterest, {
    x: headerTableX + headerTableWidth / 2 + paddingVertical,
    y: headerTableY + rowHeight * 2 - paddingHorizontal,
    size: tableFontSize,
  });
  page.drawText(common.invoicePdf.customerBusinessId, {
    x: headerTableX + paddingVertical,
    y: headerTableY + rowHeight - paddingHorizontal,
    size: tableFontSize,
  });

  // Editable fields

  let editableFieldpaddingY = 662;
  const dateField = form.createTextField('date');
  dateField.addToPage(page, {
    x: headerTableX + 1,
    y: editableFieldpaddingY,
    height: textFieldHeight,
    width: 78,
    borderColor: white,
    borderWidth: 0,
  });
  dateField.setFontSize(formFont);

  const invoiceNumberField = form.createTextField('invoiceNumber');
  invoiceNumberField.addToPage(page, {
    x: headerTableX + headerTableWidth / 3 + 1,
    y: editableFieldpaddingY,
    height: textFieldHeight,
    width: 78,
    borderColor: white,
    borderWidth: 0,
  });
  invoiceNumberField.setFontSize(formFont);
  invoiceNumberField.disableMultiline();
  invoiceNumberField.setMaxLength(12);

  const customerNumber = form.createTextField('customerNumber');
  customerNumber.addToPage(page, {
    x: headerTableX + 161,
    y: editableFieldpaddingY,
    height: textFieldHeight,
    width: 78,
    borderColor: white,
    borderWidth: 0,
  });
  customerNumber.setFontSize(formFont);

  editableFieldpaddingY = editableFieldpaddingY - textFieldHeight * 2 + 5;
  const dueDate = form.createTextField('dueDate');
  dueDate.addToPage(page, {
    x: headerTableX + 1,
    y: editableFieldpaddingY,
    height: textFieldHeight,
    width: headerTableWidth / 2 - 2,
    borderColor: white,
    borderWidth: 0,
  });
  dueDate.setFontSize(formFont);

  const paymentTerm = form.createTextField('paymentTerm');
  paymentTerm.addToPage(page, {
    x: headerTableX + headerTableWidth / 2 + 1,
    y: editableFieldpaddingY,
    height: textFieldHeight,
    width: headerTableWidth / 2 - 2,
    borderColor: white,
    borderWidth: 0,
  });
  paymentTerm.setFontSize(formFont);
  paymentTerm.setText(paymentTermPreValue);

  editableFieldpaddingY = editableFieldpaddingY - textFieldHeight * 2 + 6;
  const noteTime = form.createTextField('noteTime');
  noteTime.addToPage(page, {
    x: headerTableX + 1,
    y: editableFieldpaddingY,
    height: textFieldHeight,
    width: headerTableWidth / 2 - 2,
    borderColor: white,
    borderWidth: 0,
  });
  noteTime.setFontSize(formFont);
  noteTime.setText(noteTimePreValue);

  const latePayment = form.createTextField('latePayment');
  latePayment.addToPage(page, {
    x: headerTableX + headerTableWidth / 2 + 1,
    y: editableFieldpaddingY,
    height: textFieldHeight,
    width: headerTableWidth / 2 - 2,
    borderColor: white,
    borderWidth: 0,
  });
  latePayment.setFontSize(formFont);
  latePayment.setText(latePaymentIntPreValue);

  editableFieldpaddingY = editableFieldpaddingY - textFieldHeight * 2 + 6;
  const businessID = form.createTextField('businessID');
  businessID.addToPage(page, {
    x: headerTableX + 1,
    y: editableFieldpaddingY,
    height: textFieldHeight,
    width: headerTableWidth - 2,
    borderColor: white,
    borderWidth: 0,
  });
  businessID.setFontSize(formFont);
  businessID.setText(invoiceData.company.businessId);

  // INVOICE SUMMARY

  const headerY = 500;

  page.drawText(common.invoicePdf.month, { x: pagePadding, y: headerY, size: bodyTextSize });
  page.drawText(common.invoicePdf.totalHours, {
    x: pagePadding + 100,
    y: headerY,
    size: bodyTextSize,
  });
  page.drawText(common.invoicePdf.vat, { x: pagePadding + 250, y: headerY, size: bodyTextSize });
  page.drawText(common.invoicePdf.totalTax, {
    x: pagePadding + 350,
    y: headerY,
    size: bodyTextSize,
  });

  page.drawLine({
    start: { x: pagePadding, y: headerY - 5 },
    end: { x: pageWidth - pagePadding, y: headerY - 4 },
    thickness: 0.5,
  });

  page.drawText(getMonthName(invoiceData.invoicingDate, common), {
    x: pagePadding,
    y: headerY - 20,
    size: bodyTextSize,
  });
  page.drawText(taxRateValue, { x: pagePadding + 250, y: headerY - 20, size: bodyTextSize });
  page.drawText(replaceFromDotToComma(totalSumWithTax), {
    x: pagePadding + 350,
    y: headerY - 20,
    size: bodyTextSize,
  });

  page.drawText(common.invoicePdf.totalTaxFree, {
    x: pagePadding + 280,
    y: headerY - 70,
    size: bodyTextSize,
  });
  page.drawText(replaceFromDotToComma(getRoundedValue(roundedSum)), {
    x: pagePadding + 430,
    y: headerY - 70,
    size: bodyTextSize,
  });
  page.drawText(common.invoicePdf.totalTaxableEur, {
    x: pagePadding + 280,
    y: headerY - 85,
    size: bodyTextSize,
  });
  page.drawText(replaceFromDotToComma(totalSumWithTax), {
    x: pagePadding + 430,
    y: headerY - 85,
    size: bodyTextSize,
  });

  // FOOTER TABLE

  const footerTableX = pagePadding;
  const footerTableY = pagePadding;
  const footerTableHeight = 100;
  const footerTableWidth = pageWidth - 2 * pagePadding;
  const bottomRowOffset = 10;

  page.drawRectangle({
    x: footerTableX,
    y: footerTableY,
    width: footerTableWidth,
    height: footerTableHeight,
    borderWidth: 0.5,
    color: white,
  });

  //Horizontal lines

  page.drawLine({
    start: { x: footerTableX, y: footerTableY + rowHeight * 2 + bottomRowOffset },
    end: { x: footerTableX + footerTableWidth, y: footerTableY + rowHeight * 2 + bottomRowOffset },
    thickness: 0.5,
  });

  page.drawLine({
    start: { x: footerTableX, y: footerTableY + rowHeight + bottomRowOffset },
    end: { x: footerTableX + footerTableWidth, y: footerTableY + rowHeight + bottomRowOffset },
    thickness: 0.5,
  });

  //Vertical lines
  page.drawLine({
    start: { x: footerTableX + footerTableWidth / 2, y: footerTableY },
    end: {
      x: footerTableX + footerTableWidth / 2,
      y: footerTableY + rowHeight * 2 + bottomRowOffset,
    },
    thickness: 0.5,
  });

  page.drawLine({
    start: {
      x: footerTableX + footerTableWidth / 3,
      y: footerTableY + rowHeight * 2 + bottomRowOffset,
    },
    end: { x: footerTableX + footerTableWidth / 3, y: footerTableY + footerTableHeight },
    thickness: 0.5,
  });

  page.drawLine({
    start: {
      x: footerTableX + (2 * footerTableWidth) / 3,
      y: footerTableY + rowHeight * 2 + bottomRowOffset,
    },
    end: { x: footerTableX + (2 * footerTableWidth) / 3, y: footerTableY + footerTableHeight },
    thickness: 0.5,
  });

  // FOOTER TABLE CONTENT

  page.drawText(common.invoicePdf.dueDate, {
    x: footerTableX + paddingVertical,
    y: footerTableY + rowHeight * 3 + bottomRowOffset - paddingHorizontal,
    size: tableFontSize,
  });
  page.drawText(common.invoicePdf.referenceNumber, {
    x: footerTableX + footerTableWidth / 3 + paddingVertical,
    y: footerTableY + rowHeight * 3 + bottomRowOffset - paddingHorizontal,
    size: tableFontSize,
  });
  page.drawText(common.invoicePdf.inTotal, {
    x: footerTableX + (2 * footerTableWidth) / 3 + paddingVertical,
    y: footerTableY + rowHeight * 3 + bottomRowOffset - paddingHorizontal,
    size: tableFontSize,
  });
  page.drawText(common.invoicePdf.iban, {
    x: footerTableX + paddingVertical,
    y: footerTableY + rowHeight * 2 + bottomRowOffset - paddingHorizontal,
    size: tableFontSize,
  });
  page.drawText(common.invoicePdf.bic, {
    x: footerTableX + footerTableWidth / 2 + paddingVertical,
    y: footerTableY + rowHeight * 2 + bottomRowOffset - paddingHorizontal,
    size: tableFontSize,
  });

  let editableFieldBottomPaddingY = footerTableY + rowHeight * 2 + bottomRowOffset + 2;

  const dueDateBottom = form.createTextField('dueDateBottom');
  dueDateBottom.addToPage(page, {
    x: footerTableX + paddingVertical,
    y: editableFieldBottomPaddingY,
    height: rowHeight - 12,
    width: footerTableWidth / 3 - 3,
    borderColor: white,
    borderWidth: 0,
  });
  dueDateBottom.setFontSize(formFont);

  const referenceNumber = form.createTextField('referenceNumber');
  referenceNumber.addToPage(page, {
    x: footerTableX + footerTableWidth / 3 + paddingVertical,
    y: editableFieldBottomPaddingY,
    height: rowHeight - 12,
    width: footerTableWidth / 3 - 3,
    borderColor: white,
    borderWidth: 0,
  });
  referenceNumber.setFontSize(formFont);

  const inTotal = form.createTextField('inTotal');
  inTotal.addToPage(page, {
    x: footerTableX + (2 * footerTableWidth) / 3 + paddingVertical,
    y: editableFieldBottomPaddingY,
    height: rowHeight - 12,
    width: footerTableWidth / 3 - 3,
    borderColor: white,
    borderWidth: 0,
  });
  inTotal.setFontSize(formFont);
  inTotal.enableReadOnly();
  inTotal.setText(replaceFromDotToComma(totalSumWithTax) + ' €');

  editableFieldBottomPaddingY = editableFieldBottomPaddingY - rowHeight;
  const iban = form.createTextField('iban');
  iban.addToPage(page, {
    x: footerTableX + paddingVertical,
    y: editableFieldBottomPaddingY,
    height: rowHeight - 12,
    width: footerTableWidth / 2 - 3,
    borderColor: white,
    borderWidth: 0,
  });
  iban.setFontSize(formFont);

  const bic = form.createTextField('bic');
  bic.addToPage(page, {
    x: footerTableX + footerTableWidth / 2 + paddingVertical,
    y: editableFieldBottomPaddingY,
    height: rowHeight - 12,
    width: footerTableWidth / 2 - 3,
    borderColor: white,
    borderWidth: 0,
  });
  bic.setFontSize(formFont);

  editableFieldBottomPaddingY = editableFieldBottomPaddingY - rowHeight - 11;
  const address1 = form.createTextField('address1');
  address1.addToPage(page, {
    x: footerTableX + paddingVertical,
    y: editableFieldBottomPaddingY,
    height: rowHeight + 8,
    width: footerTableWidth / 2 - 3,
    borderColor: white,
    borderWidth: 0,
  });
  address1.setFontSize(formFont);
  address1.enableMultiline();
  address1.enableReadOnly();
  address1.setText(
    invoiceData.company.name +
      '\n' +
      invoiceData.company.address +
      '\n' +
      invoiceData.company.postCode +
      ' ' +
      invoiceData.company.city
  );

  const address2 = form.createTextField('address2');
  address2.addToPage(page, {
    x: footerTableX + footerTableWidth / 2 + paddingVertical,
    y: editableFieldBottomPaddingY,
    height: rowHeight + 8,
    width: footerTableWidth / 2 - 3,
    borderColor: white,
    borderWidth: 0,
  });
  address2.setFontSize(formFont);
  address2.enableMultiline();
  address2.enableReadOnly();
  address2.setText(
    common.invoicePdf.businessID +
      ': ' +
      invoiceData.company.businessId +
      '\n' +
      common.invoicePdf.phone +
      ': ' +
      invoiceData.company.phone +
      '\n' +
      common.invoicePdf.email +
      ': ' +
      invoiceData.company.email
  );

  // REPORT TABLE
  // Report table layout constants

  const reportPaddingY = 700;
  const reportHeaderHorizontalSpacing = 140;
  const reportHeaderVerticalSpacing = 20;
  const rowHeightReport = 25;

  let reportRowVerticalSpacing = 0;
  let totalHeight = 0;
  let totalWorkedMinutes = 0;
  let heightIncrementFactor = 0;

  let newPage!: PDFPage;

  for (let index = 0; index < reports.length; index++) {
    if (totalHeight % pageHeight == 0) {
      // create new page

      newPage = pdfDoc.addPage([pageWidth, pageHeight]);
      heightIncrementFactor = 0;

      const pageNumber = totalHeight / pageHeight + 1;

      if (pageNumber == 1) {
        // REPORT TABLE TITLE

        newPage.drawText(getMonthName(invoiceData.invoicingDate, common), {
          x: pagePadding,
          y: reportPaddingY,
          size: tableFontSize,
        });

        // CREATE TABLE HEADER

        newPage.drawText(common.invoicePdf.task, {
          x: pagePadding,
          y: reportPaddingY - reportHeaderVerticalSpacing,
          size: tableFontSize,
        });
        newPage.drawText(common.invoicePdf.priceInEur, {
          x: pagePadding + reportHeaderHorizontalSpacing,
          y: reportPaddingY - reportHeaderVerticalSpacing,
          size: tableFontSize,
        });
        newPage.drawText(common.invoicePdf.hours, {
          x: pagePadding + reportHeaderHorizontalSpacing * 2,
          y: reportPaddingY - reportHeaderVerticalSpacing,
          size: tableFontSize,
        });
        newPage.drawText(common.invoicePdf.totalTax, {
          x: pagePadding + reportHeaderHorizontalSpacing * 3,
          y: reportPaddingY - reportHeaderVerticalSpacing,
          size: tableFontSize,
        });

        newPage.drawLine({
          start: { x: pagePadding, y: reportPaddingY - reportHeaderVerticalSpacing - 5 },
          end: { x: pageWidth - pagePadding, y: reportPaddingY - reportHeaderVerticalSpacing - 5 },
          thickness: 0.5,
        });
      }
    }

    reportRowVerticalSpacing = heightIncrementFactor + 2;
    const element = reports[index];
    const tax = taxRate * element.calculatedCost + element.calculatedCost;

    newPage.drawRectangle({
      x: pagePadding,
      y: reportPaddingY - reportHeaderVerticalSpacing * reportRowVerticalSpacing - 16,
      width: pageWidth - pagePadding * 2,
      height: rowHeight,
      color: index % 2 == 0 ? gray : white,
    });

    // display columns data

    newPage.drawText(element.taskName, {
      x: pagePadding,
      y: reportPaddingY - reportHeaderVerticalSpacing * reportRowVerticalSpacing,
      size: tableFontSize,
    });
    newPage.drawText(replaceFromDotToComma(getRoundedValue(element.calculatedCost)), {
      x: pagePadding + reportHeaderHorizontalSpacing,
      y: reportPaddingY - reportHeaderVerticalSpacing * reportRowVerticalSpacing,
      size: tableFontSize,
    });
    newPage.drawText(formatToHoursAndMinutes(element.workedMinutes), {
      x: pagePadding + reportHeaderHorizontalSpacing * 2,
      y: reportPaddingY - reportHeaderVerticalSpacing * reportRowVerticalSpacing,
      size: tableFontSize,
    });
    newPage.drawText(replaceFromDotToComma(getRoundedValue(tax)), {
      x: pagePadding + reportHeaderHorizontalSpacing * 3,
      y: reportPaddingY - reportHeaderVerticalSpacing * reportRowVerticalSpacing,
      size: tableFontSize,
    });

    totalWorkedMinutes = totalWorkedMinutes + element.workedMinutes;
    heightIncrementFactor++;
    totalHeight = rowHeightReport * (heightIncrementFactor + 1);
  }

  newPage.drawLine({
    start: {
      x: pagePadding,
      y: reportPaddingY - reportHeaderVerticalSpacing * reportRowVerticalSpacing - 15,
    },
    end: {
      x: pageWidth - pagePadding,
      y: reportPaddingY - reportHeaderVerticalSpacing * reportRowVerticalSpacing - 15,
    },
    thickness: 0.5,
  });

  newPage.drawText(formatToHoursAndMinutes(totalWorkedMinutes), {
    x: pagePadding + reportHeaderHorizontalSpacing * 2,
    y: reportPaddingY - reportHeaderVerticalSpacing * reportRowVerticalSpacing - 27,
    size: tableFontSize,
  });
  newPage.drawText(replaceFromDotToComma(totalSumWithTax), {
    x: pagePadding + reportHeaderHorizontalSpacing * 3,
    y: reportPaddingY - reportHeaderVerticalSpacing * reportRowVerticalSpacing - 27,
    size: tableFontSize,
  });

  // Display total hours in Page 1

  page.drawText(formatToHoursAndMinutes(totalWorkedMinutes), {
    x: pagePadding + 100,
    y: headerY - 20,
    size: bodyTextSize,
  });

  // Serialize the PDFDocument to bytes (a Uint8Array)

  const pdfBytes = await pdfDoc.save();

  const file = new Blob([pdfBytes], {
    type: 'application/pdf',
  });

  const fileURL = URL.createObjectURL(file);
  const formPdfBytes = await fetch(fileURL).then((res) => res.arrayBuffer());
  const pdfDocV2 = await PDFDocument.load(formPdfBytes);
  const pdfBytesV2 = await pdfDocV2.save();

  const fileV2 = new Blob([pdfBytesV2], {
    type: 'application/pdf',
  });

  const fileURLV2 = URL.createObjectURL(fileV2);

  window.open(fileURLV2);
};
