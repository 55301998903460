export const getMonthName = (field: any, common: any) => {
  const monthNumber = new Date(field).getMonth() + 1;
  let monthText = '';
  switch (monthNumber) {
    case 1:
      monthText = common.months.january;
      break;
    case 2:
      monthText = common.months.february;
      break;
    case 3:
      monthText = common.months.march;
      break;
    case 4:
      monthText = common.months.april;
      break;
    case 5:
      monthText = common.months.may;
      break;
    case 6:
      monthText = common.months.june;
      break;
    case 7:
      monthText = common.months.july;
      break;
    case 8:
      monthText = common.months.august;
      break;
    case 9:
      monthText = common.months.september;
      break;
    case 10:
      monthText = common.months.october;
      break;
    case 11:
      monthText = common.months.november;
      break;
    case 12:
      monthText = common.months.december;
      break;

    default:
      break;
  }
  const year = new Date(field).getFullYear();
  return monthText + ' ' + year;
};

export const formatToFinnishDate = (field: any) => {
  const day = new Date(field).getDate();
  const month = new Date(field).getMonth() + 1;
  const year = new Date(field).getFullYear();
  return day + '.' + month + '.' + year;
};

export const reverseMonthName = (monthName: any, common: any) => {
  const monthYearArray = monthName.split(' ');
  const monthText = monthYearArray[0];
  monthYearArray[1] = parseInt(monthYearArray[1]);
  switch (monthText) {
    case common.months.january:
      monthYearArray[0] = 1;
      break;
    case common.months.february:
      monthYearArray[0] = 2;
      break;
    case common.months.march:
      monthYearArray[0] = 3;
      break;
    case common.months.april:
      monthYearArray[0] = 4;
      break;
    case common.months.may:
      monthYearArray[0] = 5;
      break;
    case common.months.june:
      monthYearArray[0] = 6;
      break;
    case common.months.july:
      monthYearArray[0] = 7;
      break;
    case common.months.august:
      monthYearArray[0] = 8;
      break;
    case common.months.september:
      monthYearArray[0] = 9;
      break;
    case common.months.october:
      monthYearArray[0] = 10;
      break;
    case common.months.november:
      monthYearArray[0] = 11;
      break;
    case common.months.december:
      monthYearArray[0] = 12;
      break;

    default:
      break;
  }
  return monthYearArray;
};
