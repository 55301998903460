const isNumber = (value: any): boolean => {
  const stringValue = value.toString();
  if (/[A-Za-z]/.test(stringValue)) {
    return false;
  }

  const dotCount = (stringValue.match(/\./g) || []).length;
  const commaCount = (stringValue.match(/,/g) || []).length;
  if (dotCount + commaCount > 1) {
    return false;
  }

  if (
    /[+-]?([1-9]\d*(,\d*[1-9])?|0,\d*[1-9]+)|\d+(,\d*[1-9])?/.test(stringValue) ||
    /[+-]?([1-9]\d*(\.\d*[1-9])?|0\.\d*[1-9]+)|\d+(\.\d*[1-9])?/.test(stringValue)
  ) {
    return true;
  }

  return false;
};

export default isNumber;
