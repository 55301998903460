import MaterialTable from 'material-table';
import { useEffect, useState } from 'react';
import { deleteTask, getTasks } from '../../api/tasks';
import Task from '../Dialogs/Task';
import common from '../../strings/common.json';
import LoadIndicator from '../../components/LoadIndicator';

const Tasks = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (value: any) => void;
}): JSX.Element => {
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState();
  const [refreshResults, setRefreshResults] = useState(true);
  const [loading, setLoading] = useState(true);
  const columns = [
    {
      field: 'name',
    },
  ];
  const actions = [
    {
      icon: 'edit',
      tooltip: common.taskDialog.edit,
      // @ts-ignore
      onClick: (e: any, rowData: any) => selectTask(e, rowData),
    },
    {
      icon: 'delete',
      tooltip: common.taskDialog.delete,
      // @ts-ignore
      onClick: (e: any, rowData: any) => deleteSelectedTask(e, rowData),
    },
  ];

  const deleteSelectedTask = async (e: any, rowData: any) => {
    if (window.confirm(common.taskDialog.confirmDelete)) {
      const response = await deleteTask(rowData.id);
      if (response !== 200) {
        window.alert(common.taskDialog.errorDelete);
        return;
      }
      setRefreshResults(true);
    }
  };

  const selectTask = (e: any, rowData: any) => {
    setSelectedTask(rowData);
    setOpen(true);
  };

  useEffect(() => {
    const get = async () => {
      setLoading(true);
      const response = await getTasks();
      setTasks(response);
      setLoading(false);
    };
    if (refreshResults) {
      get();
      setRefreshResults(false);
    }
  }, [refreshResults]);

  if (loading) {
    return <LoadIndicator />;
  }

  return (
    <>
      <MaterialTable
        title=""
        localization={{
          header: {
            actions: '',
          },
        }}
        columns={columns}
        data={tasks}
        actions={actions}
        options={{
          actionsColumnIndex: -1,
          sorting: false,
          draggable: false,
          toolbar: false,
          header: false,
          paging: false,
        }}
      />
      <Task
        open={open}
        setOpen={setOpen}
        refreshPage={() => setRefreshResults(true)}
        selectedTask={selectedTask}
        setSelectedTask={setSelectedTask}
      />
    </>
  );
};

export default Tasks;
