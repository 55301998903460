import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Formik, Form } from 'formik';
import DialogButtons from '../../components/layout/DialogButtons';
import common from '../../strings/common.json';
import { addCompanyApi, updateCompanyApi } from '../../api/companies';
import { resetValidity, setEmptyValidityMessage } from '../../components/helpers/emptyValidation';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    paddingBottom: '5vh',
  },
  MuiDialogContentText: {
    fontSize: 18,
    color: 'black',
    paddingTop: 20,
  },
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
}));

const Company = ({
  open,
  setOpen,

  selectedCompany,
  refreshPage,
  setSelectedCompany,
}: {
  open: boolean;
  setOpen: (value: any) => void;

  selectedCompany: any;
  refreshPage: () => void;
  setSelectedCompany: (value: any) => void;
}): JSX.Element => {
  const classes = useStyles();

  const add = async (values: {}) => {
    const response = await addCompanyApi(values);
    if (response !== 200) {
      window.alert(common.companyDialog.errorAdd);
      return;
    }
    refreshPage();
  };

  const update = async (values: {}) => {
    const response = await updateCompanyApi(values, selectedCompany.id);
    if (response !== 200) {
      window.alert(common.companyDialog.errorEdit);
      return;
    }
    refreshPage();
  };

  return (
    <Dialog fullWidth={true} maxWidth="sm" classes={{ paper: classes.dialogPaper }} open={open}>
      <Formik
        initialValues={{
          name: selectedCompany ? selectedCompany.name : '',
          businessId: selectedCompany ? selectedCompany.businessId : '',
          address: selectedCompany ? selectedCompany.address : '',
          phone: selectedCompany ? selectedCompany.phone : '',
          email: selectedCompany ? selectedCompany.email : '',
          postCode: selectedCompany ? selectedCompany.postCode : null,
          city: selectedCompany ? selectedCompany.city : '',
        }}
        onSubmit={async (values, { resetForm }) => {
          if (selectedCompany) {
            update(values);
          } else {
            add(values);
          }
          resetForm({});
          setOpen(false);

          setSelectedCompany(undefined);
        }}
        onReset={() => {
          setOpen(false);
          setSelectedCompany(undefined);
        }}
      >
        {({ values, handleChange }) => (
          <Form>
            <DialogTitle id="form-dialog-title">
              {selectedCompany ? common.companyDialog.edit : common.companyDialog.newCompany}
            </DialogTitle>
            <DialogContent>
              <div style={{ width: '60%' }}>
                <TextField
                  fullWidth
                  required
                  onInput={(e) => resetValidity(e)}
                  onInvalid={(e) => setEmptyValidityMessage(e)}
                  id="name"
                  name="name"
                  label={common.companyDialog.name}
                  value={values.name}
                  onChange={handleChange}
                ></TextField>
                <TextField
                  required
                  onInput={(e) => resetValidity(e)}
                  onInvalid={(e) => setEmptyValidityMessage(e)}
                  id="businessId"
                  name="businessId"
                  fullWidth
                  label={common.companyDialog.businessId}
                  value={values.businessId}
                  onChange={handleChange}
                ></TextField>
                <TextField
                  required
                  onInput={(e) => resetValidity(e)}
                  onInvalid={(e) => setEmptyValidityMessage(e)}
                  fullWidth
                  id="address"
                  name="address"
                  label={common.companyDialog.address}
                  value={values.address}
                  onChange={handleChange}
                ></TextField>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <TextField
                    required
                    onInput={(e) => resetValidity(e)}
                    onInvalid={(e) => setEmptyValidityMessage(e)}
                    fullWidth
                    id="city"
                    name="city"
                    style={{ width: '40%' }}
                    label={common.companyDialog.city}
                    value={values.city}
                    onChange={handleChange}
                  ></TextField>
                  <TextField
                    type="number"
                    required
                    onInput={(e) => resetValidity(e)}
                    onInvalid={(e) => setEmptyValidityMessage(e)}
                    fullWidth
                    className={classes.input}
                    id="postCode"
                    name="postCode"
                    style={{ width: '50%' }}
                    label={common.companyDialog.postcode}
                    value={values.postCode}
                    onChange={handleChange}
                  ></TextField>
                </div>
                <TextField
                  fullWidth
                  required
                  onInput={(e) => resetValidity(e)}
                  onInvalid={(e) => setEmptyValidityMessage(e)}
                  id="phone"
                  name="phone"
                  label={common.companyDialog.phone}
                  value={values.phone}
                  onChange={handleChange}
                ></TextField>
                <TextField
                  fullWidth
                  required
                  onInput={(e) => resetValidity(e)}
                  onInvalid={(e) => setEmptyValidityMessage(e)}
                  id="email"
                  name="email"
                  label={common.companyDialog.email}
                  value={values.email}
                  onChange={handleChange}
                ></TextField>
              </div>
            </DialogContent>

            <DialogActions>
              <DialogButtons />
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default Company;
