import { useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
import common from '../strings/common.json';
import colors from '../strings/colors.json';
import AssignJob from './Dialogs/AssignJob';
import { DialogContext } from '../components/context';

import { getSites } from '../api/sites';
import { getUsers } from '../api/users';
import { getAssignmentsApi, deleteAssignmentApi, getAssignmentApi } from '../api/assignment';

function Assign(): JSX.Element {
  const { setAssignJob, assignJob } = useContext(DialogContext);
  const [sites, setSites] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedAssignment, setSelectedAssignment] = useState();
  const [assignments, setassignments] = useState([]);

  const [refreshResults, setRefreshResults] = useState(false);
  const [getSelectedAssignment, setGetSelectedAssignment] = useState({
    run: false,
    assignment: '',
  });

  useEffect(() => {
    const get = async () => {
      const sites = await getSites();
      const employees = await getUsers();
      setSites(sites);
      setEmployees(employees);
    };

    get();
  }, []);

  useEffect(() => {
    if (refreshResults) {
      const get = async () => {
        const newAssignments = await getAssignmentsApi();
        setassignments(newAssignments.assignments);
        setRefreshResults(false);
      };
      get();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshResults]);

  useEffect(() => {
    if (!assignJob) {
      const get = async () => {
        const newAssignments = await getAssignmentsApi();
        setassignments(newAssignments.assignments);
        setRefreshResults(false);
      };
      get();
    }
  }, [assignJob]);

  useEffect(() => {
    if (getSelectedAssignment.run) {
      const get = async () => {
        const assignment = await getAssignmentApi(getSelectedAssignment.assignment);
        setSelectedAssignment(assignment);
        setAssignJob(true);
        setGetSelectedAssignment({ run: false, assignment: '' });
      };
      get();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSelectedAssignment]);

  const handleDeleteAssignment = async (e: any, rowData: any) => {
    if (window.confirm(common.jobDialog.confirmDelete)) {
      const response = await deleteAssignmentApi(rowData.id);
      if (response !== 200) {
        window.alert(common.jobDialog.errorDelete);
        return;
      }
    }
    setRefreshResults(true);
  };

  const handleEditassignment = (rowData: any) => {
    setGetSelectedAssignment({ run: true, assignment: rowData.id });
  };

  const columns = [
    { title: common.assignTable.employee, field: 'user.name' },
    { title: common.assignTable.site, field: 'site.name' },
    { title: common.assignTable.task, field: 'task.name' },
    { title: common.assignTable.moreInfo, field: 'moreInfo' },
  ];

  const actions = [
    {
      icon: 'edit',
      tooltip: common.jobDialog.edit,
      // @ts-ignore
      onClick: (e, rowData) => handleEditassignment(rowData),
    },
    {
      icon: 'delete',
      tooltip: common.jobDialog.delete,
      // @ts-ignore
      onClick: (e: any, rowData: any) => handleDeleteAssignment(e, rowData),
    },
  ];

  return (
    <div>
      <MaterialTable
        title=""
        localization={{
          header: {
            actions: '',
          },
        }}
        columns={columns}
        data={assignments}
        actions={actions}
        options={{
          headerStyle: { backgroundColor: colors.tableHeaderGrey },
          actionsColumnIndex: -1,
          sorting: false,
          draggable: false,

          pageSize: 10,
        }}
      />
      <AssignJob
        sites={sites}
        employees={employees}
        selectedAssignment={selectedAssignment}
        setSelectedAssignment={setSelectedAssignment}
      />
    </div>
  );
}

export default Assign;
