import { api } from './fetchUtils';

export const getMachines = async () => {
  try {
    const response = await api.get('/api/Machines');
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    console.log('Error: ', err);
    return [];
  }
};

export const getMachineTypes = async () => {
  try {
    const response = await api.get('/api/MachineTypes');
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    console.log('Error: ', err);
    return [];
  }
};

export const addMachine = async (values: {}) => {
  try {
    const response = await api.post('/api/Machines', values);
    return response.status;
  } catch (err) {
    console.log('Error: ', err);
    return null;
  }
};

export const updateMachine = async (values: {}, id: string) => {
  try {
    const response = await api.put(`/api/Machines/${id}`, values);
    return response.status;
  } catch (err) {
    console.log('Error: ', err);
    return null;
  }
};

export const deleteMachine = async (id: string) => {
  try {
    const response = await api.delete(`/api/Machines/${id}`);
    return response.status;
  } catch (err) {
    console.log('Error: ', err);
    return null;
  }
};
