import React, { useContext, useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import common from '../strings/common.json';
import colors from '../strings/colors.json';
import Employee from './Dialogs/Employee';
import { deleteUser, getUsers } from '../api/users';
import { DialogContext } from '../components/context';

const Employees = (): JSX.Element => {
  const [employees, setEmployees] = useState([]);
  const { setAddEmployee } = useContext(DialogContext);
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [refreshResults, setRefreshResults] = useState(true);
  const columns = [
    { title: common.usersTable.employee, field: 'name' },
    { title: common.usersTable.company, field: 'company.name' },
    { title: common.usersTable.personalId, field: 'personnelNumber' },
  ];
  const actions = [
    {
      icon: 'edit',
      tooltip: common.employeeDialog.edit,
      // @ts-ignore
      onClick: (e: any, rowData: any) => selectEmployee(e, rowData),
    },
    {
      icon: 'delete',
      tooltip: common.employeeDialog.delete,
      // @ts-ignore
      onClick: (e: any, rowData: any) => deleteEmployee(e, rowData),
    },
  ];

  const deleteEmployee = async (e: any, rowData: any) => {
    if (window.confirm(common.employeeDialog.confirmDelete)) {
      const response = await deleteUser(rowData.id);
      if (response !== 200) {
        window.alert(common.employeeDialog.errorDelete);
        return;
      }
    }
    setRefreshResults(true);
  };

  const selectEmployee = (e: any, rowData: any) => {
    setSelectedEmployee(rowData);
    setAddEmployee(true);
  };

  useEffect(() => {
    if (refreshResults) {
      const getEmployees = async () => {
        const response = await getUsers();
        setEmployees(response);
      };
      getEmployees();
      setRefreshResults(false);
    }
  }, [refreshResults]);

  return (
    <div>
      <MaterialTable
        title=""
        localization={{
          header: {
            actions: '',
          },
        }}
        columns={columns}
        data={employees}
        actions={actions}
        options={{
          headerStyle: { backgroundColor: colors.tableHeaderGrey },
          actionsColumnIndex: -1,
          pageSize: 10,
        }}
      />
      <Employee
        refreshPage={() => setRefreshResults(true)}
        selectedEmployee={selectedEmployee}
        setSelectedEmployee={setSelectedEmployee}
      />
    </div>
  );
};

export default Employees;
