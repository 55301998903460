import { Button } from 'reactstrap';
import '../../style/layout/Buttons.css';

const getClassName = (className?: string): string =>
  className === undefined ? 'basic-button' : `basic-button ${className}`;

const BasicButton = ({
  children,
  className,
  onClick,
  size,
}: {
  children: string | JSX.Element;
  className?: string;
  onClick: () => void;
  size?: string;
}): JSX.Element => (
  <Button className={getClassName(className)} onClick={onClick} size={size}>
    {children}
  </Button>
);

export default BasicButton;
