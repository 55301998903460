import colors from '../strings/colors.json';
import Loader from 'react-loader-spinner';

function LoadIndicator() {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: '2rem',
      }}
    >
      <Loader type="Oval" color={colors.grey} height={50} width={100} />
    </div>
  );
}

export default LoadIndicator;
