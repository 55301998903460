export const formatEuros = (field: number) => {
  const roundedNumber = Math.round(field * 100) / 100;
  const stringNumber = roundedNumber.toString().replace('.', ',');
  if (stringNumber.includes(',') && stringNumber.split(',')[1].length === 1) {
    return `${stringNumber}0 €`;
  } else if (stringNumber.includes(',')) {
    return `${stringNumber} €`;
  }
  return stringNumber +',00 €';
};
