import { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { getDepartmentApi, deleteDepartmentApi } from '../../api/departments';
import Department from '../Dialogs/Department';
import { updateDepartmentApi } from '../../api/departments';
import LoadIndicator from '../../components/LoadIndicator';
import common from '../../strings/common.json';

interface DepartmentsProps {
  open: boolean;
}

function Departments({ open }: DepartmentsProps) {
  const [departments, setDepartments] = useState([]);
  const [department, setDepartment] = useState();
  const [openEditDepartment, setEditDepartment] = useState(false);
  const [loading, setloading] = useState(false);

  const fetchDepartments = async () => {
    setloading(true);
    const departments = await getDepartmentApi();
    setDepartments(departments);
    setloading(false);
  };

  useEffect(() => {
    fetchDepartments();
  }, [open, openEditDepartment]);

  const handleDelete = async (department: any) => {
    if (window.confirm(common.departmentDialog.confirmDelete)) {
      const result = await deleteDepartmentApi(department.id);
      if (result !== 200) {
        window.alert(common.departmentDialog.errorDelete);
      }
      fetchDepartments();
    }
  };

  const handleEdit = async (department: any) => {
    setEditDepartment(true);
    setDepartment(department);
  };

  const editDepartment = async (editedDepartment: any) => {
    //@ts-ignore
    const result = await updateDepartmentApi(editedDepartment.department, department.id);
    if (result !== 200) {
      window.alert(common.departmentDialog.errorEdit);
    }
  };

  const columns = [
    {
      field: 'name',
    },
  ];
  const actions = [
    (rowData: any) => ({
      icon: 'edit',
      tooltip: common.departmentDialog.edit,
      // @ts-ignore
      onClick: (event, rowData: any) => {
        handleEdit(rowData);
      },
      disabled: rowData.useSplitCost
    }),
    (rowData: any) => ({
      icon: 'delete',
      tooltip: common.departmentDialog.delete,
      // @ts-ignore
      onClick: (event, rowData: any) => {
        handleDelete(rowData);
      },
      disabled: rowData.useSplitCost
    })
  ];
  if (loading) {
    return <LoadIndicator />;
  }
  return (
    <>
      <MaterialTable
        title=""
        localization={{
          header: {
            actions: '',
          },
        }}
        columns={columns}
        data={departments}
        actions={actions}
        options={{
          actionsColumnIndex: -1,
          sorting: false,
          draggable: false,
          toolbar: false,
          header: false,
          paging: false,
        }}
      />
      <Department
        originalValue={department}
        open={openEditDepartment}
        setOpen={setEditDepartment}
        handleSubmit={editDepartment}
      />
    </>
  );
}

export default Departments;
