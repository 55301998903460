import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Formik, Form } from 'formik';
import DialogButtons from '../../components/layout/DialogButtons';
import common from '../../strings/common.json';
import { MenuItem } from '@material-ui/core';
import { addMachine, updateMachine } from '../../api/machines';
import { Select, InputLabel, FormControl } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { resetValidity, setEmptyValidityMessage } from '../../components/helpers/emptyValidation';
import numericStringToFloat from '../../components/helpers/numericStringToFloat';
import isNumber from '../../components/helpers/isNumber';
import replaceFromDotToComma from '../../components/helpers/replaceFromDotToComma';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    paddingBottom: '5vh',
  },
  MuiDialogContentText: {
    fontSize: 18,
    color: 'black',
    paddingTop: 20,
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(0),
  },
}));

const Machine = ({
  open,
  setOpen,
  machineTypes,
  selectedMachine,
  refreshPage,
  setSelectedMachine,
}: {
  open: boolean;
  setOpen: (value: any) => void;
  machineTypes: { id: string; name: string }[];
  selectedMachine: any;
  refreshPage: () => void;
  setSelectedMachine: (value: any) => void;
}): JSX.Element => {
  const classes = useStyles();
  const [machineType, setMachineType] = useState(
    selectedMachine ? selectedMachine.machineTypeId : ''
  );

  const add = async (values: {}) => {
    const response = await addMachine(values);
    if (response !== 200) {
      window.alert(common.machineDialog.errorAdd);
      return;
    }
    refreshPage();
  };

  const update = async (values: {}) => {
    const response = await updateMachine(values, selectedMachine.id);
    if (response !== 200) {
      window.alert(common.machineDialog.errorEdit);
      return;
    }
    refreshPage();
  };

  const handleMachineType = (e: any) => {
    e.preventDefault();
    setMachineType(e.target.value);
  };

  useEffect(() => {
    if (selectedMachine) {
      setMachineType(selectedMachine.machineTypeId);
    }
  }, [selectedMachine]);

  return (
    <Dialog fullWidth={true} maxWidth="sm" classes={{ paper: classes.dialogPaper }} open={open}>
      <Formik
        initialValues={{
          number: selectedMachine ? selectedMachine.number : '',
          hourlyRate: selectedMachine ? replaceFromDotToComma(selectedMachine.hourlyRate) : '',
        }}
        onSubmit={async (values, { resetForm }) => {
          if (isNumber(values.hourlyRate)) {
            const newValues = {
              number: values.number,
              hourlyRate: numericStringToFloat(values.hourlyRate),
              machineTypeId: machineType,
            };
            if (selectedMachine) {
              update(newValues);
            } else {
              add(newValues);
            }
            resetForm({});
            setOpen(false);
            setMachineType(undefined);
            setSelectedMachine(undefined);
          }
        }}
        onReset={() => {
          setOpen(false);
          setMachineType(undefined);
          setSelectedMachine(undefined);
        }}
      >
        {({ values, handleChange }) => (
          <Form>
            <DialogTitle id="form-dialog-title">
              {selectedMachine ? common.machineDialog.edit : common.machineDialog.newMachine}
            </DialogTitle>
            <DialogContent>
              <div style={{ width: '60%' }}>
                <TextField
                  fullWidth
                  required
                  onInput={(e) => resetValidity(e)}
                  onInvalid={(e) => setEmptyValidityMessage(e)}
                  id="number"
                  name="number"
                  label={common.machineDialog.machineNumber}
                  value={values.number}
                  onChange={handleChange}
                ></TextField>
                <TextField
                  fullWidth
                  required
                  onInput={(e) => resetValidity(e)}
                  onInvalid={(e) => setEmptyValidityMessage(e)}
                  id="hourlyRate"
                  name="hourlyRate"
                  label={common.settingsPage.hourlyRate}
                  value={values.hourlyRate}
                  onChange={handleChange}
                ></TextField>

                <FormControl required className={classes.formControl}>
                  <InputLabel id="machineTypeLabel">{common.machineDialog.machineType}</InputLabel>
                  <Select
                    labelId="machineTypeLabel"
                    id="machineTypeId"
                    value={machineType}
                    onChange={(e: any) => handleMachineType(e)}
                    className={classes.selectEmpty}
                  >
                    {machineTypes.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </DialogContent>

            <DialogActions>
              <DialogButtons />
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default Machine;
