import { api } from './fetchUtils';

export const getTasks = async () => {
  try {
    const response = await api.get('/api/Tasks');
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    console.log('Error: ', err);
    return [];
  }
};

export const addTask = async (task: any) => {
  try {
    const response = await api.post('/api/Tasks', task);
    if (response.status === 200) {
      return response.status;
    }
  } catch (err) {
    console.log('Error: ', err);
    return null;
  }
};

export const updateTask = async (task: any, id: string) => {
  try {
    const response = await api.put(`/api/Tasks/${id}`, task);
    if (response.status === 200) {
      return response.status;
    }
  } catch (err) {
    console.log('Error: ', err);
    return null;
  }
};

export const deleteTask = async (id: string) => {
  try {
    const response = await api.delete(`/api/Tasks/${id}`);
    if (response.status === 200) {
      return response.status;
    }
  } catch (err) {
    console.log('Error: ', err);
    return null;
  }
};
