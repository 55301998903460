import React, { useState, useContext } from 'react';
import { FormGroup, Input } from 'reactstrap';
import { authenticate } from '../api/authentication';
import { AuthContext } from '../components/context';
import BasicButton from '../components/layout/BasicButton';
import common from '../strings/common.json';
import '../style/layout/Login.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();
function Login(): JSX.Element {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { signIn } = useContext(AuthContext);

  const displayErrorMessage = (message: string) => {
    toast.error(message, {
      position: 'top-center',
      autoClose: 10000,
    });
  };

  const login = async () => {
    if (email.trim() === '' || password.trim() === '') {
      displayErrorMessage(common.login.incorrectCredentialsErrorMessge);
      return;
    }

    const response = await authenticate(email, password);
    if (response !== null) {
      toast.dismiss();
      signIn(response.data.accessToken, response.data.refreshToken);
    } else {
      displayErrorMessage(common.login.unauthorisedMessage);
    }
  };

  return (
    <div className="login-background">
      <div className="login-box">
        <div className="login-header-image">
          <img src={require('../assets/logo.svg').default} alt="puhas logo" />
        </div>
        <FormGroup>
          <Input
            className="login-input"
            id="login-email"
            onChange={(event) => setEmail(event.target.value)}
            value={email}
            required={true}
            placeholder={common.login.email}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                login();
              }
            }}
          />

          <Input
            className="login-input"
            id="login-password"
            onChange={(event) => setPassword(event.target.value)}
            value={password}
            type="password"
            required={true}
            placeholder={common.login.password}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                login();
              }
            }}
          />
        </FormGroup>

        <BasicButton className="login-button" onClick={login}>
          {common.login.login.toLocaleUpperCase()}
        </BasicButton>
      </div>
    </div>
  );
}

export default Login;
