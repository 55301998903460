import React from 'react';
import colors from '../../strings/colors.json';

interface HeaderProps {
  titleName: any;
  secondTitleName?: any;
  buttonName: any;
  handleClick: any;
}

function Header(props: HeaderProps) {
  const { titleName, handleClick, buttonName, secondTitleName } = props;
  return (
    <div
      style={{
        height: '56px',
        backgroundColor: colors.tableHeaderGrey,
        display: 'flex',
        // flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '15px',
        paddingRight: '20px',
      }}
    >
      <div>{titleName}</div>
      {secondTitleName && <div>{secondTitleName} </div>}

      <div style={{ cursor: 'pointer' }} onClick={() => handleClick()}>
        {buttonName}
      </div>
    </div>
  );
}

export default Header;
