import { api } from './fetchUtils';

export const getReports = async () => {
  try {
    const response = await api.get('api/Reports');
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    console.log('Error: ', err);
    return [];
  }
};

export const filterReports = async (
  year: number | null,
  month: number | null,
  departmentName: string | null,
  assignedUserName: string | null,
  siteName: string | null,
  taskName: string | null,
  machineNumber: string | null
) => {
  try {
    const response = await api.get(`/api/Reports/Filters`, {
      params: {
        year: year,
        month: month,
        departmentName: departmentName,
        assignedUserName: assignedUserName,
        siteName: siteName,
        taskName: taskName,
        machineNumber: machineNumber,
      },
    });
    return response.data;
  } catch (err) {
    console.log('Error: ', err);
    return null;
  }
};

export const deleteReport = async (id: string) => {
  try {
    const response = await api.delete(`/api/Reports/${id}`);
    return response.status;
  } catch (err) {
    console.log('Error: ', err);
    return null;
  }
};

export const getMapImage = async (reportId: string) => {
  try {
    const response = await api.get(`/api/Reports/${reportId}/RouteImage`);
    return response.data;
  } catch (err) {
    console.log('Error: ', err);
    return null;
  }
};

export const updateReport = async (values: {}, id: string) => {
  try {
    const response = await api.put(`/api/Reports/${id}`, values);
    return response.status;
  } catch (err) {
    console.log('Error: ', err);
    return null;
  }
};