import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CheckboxWithLabel } from 'formik-material-ui';
import FormGroup from '@material-ui/core/FormGroup';
import { Formik, Field, Form } from 'formik';
import { DialogContentText, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import DialogButtons from '../../components/layout/DialogButtons';
import common from '../../strings/common.json';
import { addTask, updateTask } from '../../api/tasks';
import isNumber from '../../components/helpers/isNumber';
import numericStringToFloat from '../../components/helpers/numericStringToFloat';
import replaceFromDotToComma from '../../components/helpers/replaceFromDotToComma';
import { resetValidity, setEmptyValidityMessage } from '../../components/helpers/emptyValidation';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    paddingBottom: '5vh',
  },
  MuiDialogContentText: {
    fontSize: 18,
    color: 'black',
    paddingTop: 20,
  },
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
}));

const Task = ({
  open,
  setOpen,
  refreshPage,
  selectedTask,
  setSelectedTask,
}: {
  open: boolean;
  setOpen: (value: any) => void;
  refreshPage: () => void;
  selectedTask: any;
  setSelectedTask: (value: any) => void;
}): JSX.Element => {
  const classes = useStyles();

  const add = async (values: {}) => {
    const response = await addTask(values);
    if (response !== 200) {
      window.alert(common.taskDialog.errorAdd);
      return;
    }
    refreshPage();
  };

  const update = async (values: {}) => {
    const response = await updateTask(values, selectedTask.id);
    if (response !== 200) {
      window.alert(common.taskDialog.errorEdit);
      return;
    }
    refreshPage();
  };

  const isFixedPrice = (values: any) =>
    values.priceType === 'fixedPriceRadio' && !values.needsMachine;

  const isHourlyRate = (values: any) =>
    values.priceType === 'hourlyRateRadio' && !values.needsMachine;

  const getPriceType = () =>
    selectedTask.fixedPrice !== 0 ? 'fixedPriceRadio' : 'hourlyRateRadio';

  return (
    <Dialog fullWidth={true} maxWidth="sm" classes={{ paper: classes.dialogPaper }} open={open}>
      <Formik
        initialValues={{
          name: selectedTask ? selectedTask.name : '',
          description: selectedTask ? selectedTask.description : '',
          needsMachine: selectedTask ? selectedTask.needsMachine : true,
          priceType: selectedTask ? getPriceType() : 'hourlyRateRadio',
          fixedPrice: selectedTask ? replaceFromDotToComma(selectedTask.fixedPrice) : '0',
          hourlyRate: selectedTask ? replaceFromDotToComma(selectedTask.hourlyRate) : '0',
          usesDistanceRate: selectedTask ? selectedTask.usesDistanceRate : false,
          account: selectedTask?.account ? selectedTask.account : '',
          costCenter: selectedTask?.costCenter ? selectedTask.costCenter : '',
        }}
        onSubmit={async (values, { resetForm }) => {
          let taskValues: {} | undefined = undefined;
          if (isFixedPrice(values)) {
            if (isNumber(values.fixedPrice)) {
              taskValues = {
                name: values.name,
                description: values.description,
                account: values.account,
                costCenter: values.costCenter,
                needsMachine: false,
                fixedPrice: numericStringToFloat(values.fixedPrice),
              };
            }
          } else if (isHourlyRate(values)) {
            if (isNumber(values.hourlyRate)) {
              taskValues = {
                name: values.name,
                description: values.description,
                account: values.account,
                costCenter: values.costCenter,
                needsMachine: false,
                hourlyRate: numericStringToFloat(values.hourlyRate),
                usesDistanceRate: values.usesDistanceRate,
              };
            }
          } else {
            taskValues = {
              name: values.name,
              description: values.description,
              account: values.account,
              costCenter: values.costCenter,
              needsMachine: true,
            };
          }
          if (taskValues !== undefined) {
            selectedTask ? update(taskValues) : add(taskValues);
            resetForm({});
            setOpen(false);
            setSelectedTask(undefined);
          }
        }}
        onReset={() => {
          setOpen(false);
          setSelectedTask(undefined);
        }}
      >
        {({ values, handleChange }) => (
          <Form>
            <DialogTitle id="form-dialog-title">
              {selectedTask ? common.taskDialog.edit : common.taskDialog.newTask}
            </DialogTitle>
            <DialogContent>
              <div style={{ width: '60%' }}>
                <TextField
                  fullWidth
                  required
                  id="name"
                  name="name"
                  label="Tehtävän nimi"
                  value={values.name}
                  onChange={handleChange}
                  onInput={e => resetValidity(e)}
                  onInvalid={e => setEmptyValidityMessage(e)}
                ></TextField>
                <TextField
                  fullWidth
                  required
                  id="description"
                  name="description"
                  label={common.taskDialog.taskDescription}
                  value={values.description}
                  onChange={handleChange}
                  onInput={e => resetValidity(e)}
                  onInvalid={e => setEmptyValidityMessage(e)}
                ></TextField>
                <TextField
                  fullWidth
                  required
                  id="account"
                  name="account"
                  value={values.account}
                  label={common.taskDialog.inputAccount}
                  onChange={e => {
                    if (e.target.value === '' || isNumber(e.target.value)) {
                      handleChange(e)
                    }
                  }}
                  onInput={e => resetValidity(e)}
                  onInvalid={e => setEmptyValidityMessage(e)}
                ></TextField>
                <TextField
                  fullWidth
                  required
                  id="costCenter"
                  name="costCenter"
                  value={values.costCenter}
                  label={common.taskDialog.costCenter}
                  onChange={e => {
                    if (e.target.value === '' || isNumber(e.target.value)) {
                      handleChange(e)
                    }
                  }}
                  onInput={e => resetValidity(e)}
                  onInvalid={e => setEmptyValidityMessage(e)}
                ></TextField>

                <DialogContentText classes={{ root: classes.MuiDialogContentText }}>
                  {common.taskDialog.taskOptions}
                </DialogContentText>

                <FormGroup row={true}>
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="needsMachine"
                    Label={{ label: common.taskDialog.requireMachine }}
                  />
                </FormGroup>

                {values.needsMachine ? null : (
                  <RadioGroup
                    row
                    aria-label="priceType"
                    name="priceType"
                    defaultValue="fixedPriceRadio"
                    value={values.priceType}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="hourlyRateRadio"
                      control={<Radio />}
                      label={common.settingsPage.hourlyRate}
                    />
                    <FormControlLabel
                      value="fixedPriceRadio"
                      control={<Radio />}
                      label={common.settingsPage.fixedPrice}
                    />
                  </RadioGroup>
                )}
                {isFixedPrice(values) ? (
                  <TextField
                    required={isFixedPrice(values)}
                    id="fixedPrice"
                    name="fixedPrice"
                    label={common.settingsPage.fixedPrice}
                    value={values.fixedPrice}
                    onChange={handleChange}
                  ></TextField>
                ) : null}

                {isHourlyRate(values) ? (
                  <>
                    <TextField
                      required={isHourlyRate(values)}
                      fullWidth
                      id="hourlyRate"
                      name="hourlyRate"
                      label={common.settingsPage.hourlyRate}
                      value={values.hourlyRate}
                      onChange={handleChange}
                    ></TextField>
                    <Field
                      component={CheckboxWithLabel}
                      type="checkbox"
                      name="usesDistanceRate"
                      Label={{ label: common.settingsPage.distanceRate }}
                    />
                  </>
                ) : null}
              </div>
            </DialogContent>
            <DialogActions>
              <DialogButtons />
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default Task;
