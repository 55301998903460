import { getValidAccessToken } from './authentication';

export default function useToken() {
  const getToken = async () => {
    const accessToken = await getValidAccessToken();
    return accessToken;
  };

  const saveToken = (accessToken: string, refreshToken: string) => {
    localStorage.setItem('@accessToken', accessToken);
    localStorage.setItem('@refreshToken', refreshToken);
  };

  const deleteToken = () => {
    localStorage.removeItem('@accessToken');
    localStorage.removeItem('@refreshToken');
  };

  return {
    getToken,
    saveToken,
    deleteToken,
  };
}
