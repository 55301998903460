import { useReducer, useMemo, useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Assign from './Screens/Assign';
import Reports from './Screens/Reports/Reports';
import Layout from './Layout';
import Login from './Screens/Login';
import Employees from './Screens/Employees';
import Settings from './Screens/Settings';
import './style/Theme.css';
import useToken from './api/useToken';
import { AuthContext } from './components/context';
import { DialogContext } from './components/context';
import { api } from './api/fetchUtils';
import Invoices from './Screens/Invoices';
import Invoice from './Screens/Invoice';

function App() {
  const { getToken, deleteToken, saveToken } = useToken();
  const [assignJob, setAssignJob] = useState(false);
  const [addEmployee, setAddEmployee] = useState(false);
  const [exportExcel, setExportExcel] = useState(false);

  const initialLoginState = {
    isLoading: true,
    userToken: null,
  };

  const loginReducer = (prevState: any, action: any) => {
    switch (action.type) {
      case 'RETRIEVE_TOKEN':
        return {
          ...prevState,
          userToken: action.token,
          isLoading: false,
        };
      case 'LOGIN':
        return {
          ...prevState,
          userToken: action.token,
          isLoading: false,
        };
      case 'LOGOUT':
        return {
          ...prevState,
          userToken: null,
          isLoading: false,
        };
    }
  };
  const [loginState, dispatch] = useReducer(loginReducer, initialLoginState);

  const authContext = useMemo(
    () => ({
      signIn: async (accessToken: string, refreshToken: string) => {
        saveToken(accessToken, refreshToken);
        dispatch({ type: 'LOGIN', token: accessToken });
      },
      signOut: async () => {
        deleteToken();
        dispatch({ type: 'LOGOUT' });
      },
    }),
    []
  );

  useEffect(() => {
    const retrieveToken = async () => {
      const token = await getToken();
      dispatch({ type: 'RETRIEVE_TOKEN', token: token });
    };
    retrieveToken();

    api.interceptors.request.use(async (request) => {
      let token = await getToken();
      request.headers.Authorization = token ? `Bearer ${token}` : null;
      return request;
    });

    api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          authContext.signOut();
        }
        return Promise.reject(error);
      }
    );
  }, []);

  if (loginState.isLoading) {
    return <></>;
  }

  return (
    <BrowserRouter>
      <DialogContext.Provider value={{ assignJob, setAssignJob, addEmployee, setAddEmployee, exportExcel, setExportExcel}}>
        <AuthContext.Provider value={authContext}>
          {loginState.userToken === null ? (
            <Switch>
              <Route exact path="/login">
                <Login />
              </Route>
              <Route render={() => <Redirect to={{ pathname: '/login' }} />} />
            </Switch>
          ) : (
            <Layout>
              <Switch>
                <Route exact path="/assign">
                  <Assign />
                </Route>
                <Route exact path="/reports">
                  <Reports />
                </Route>
                <Route exact path="/invoices">
                  <Invoices />
                </Route>
                <Route exact path="/invoice/:invoiceId">
                  <Invoice />
                </Route>
                <Route exact path="/employees">
                  <Employees />
                </Route>
                <Route exact path="/settings">
                  <Settings />
                </Route>
                <Route render={() => <Redirect to={{ pathname: '/assign' }} />} />
              </Switch>
            </Layout>
          )}
        </AuthContext.Provider>
      </DialogContext.Provider>
    </BrowserRouter>
  );
}

export default App;
