const getInvoiceStatus = (value: boolean, common: any) => {
  switch (value) {
    case true:
      return common.invoiceStatus.approved;
    case false:
      return common.invoiceStatus.open;
  }
};

export default getInvoiceStatus;
