import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { DialogContentText, MenuItem } from '@material-ui/core';
import DialogButtons from '../../components/layout/DialogButtons';
import common from '../../strings/common.json';
import { useEffect, useState } from 'react';
import { Select } from '@material-ui/core';
import { getTasks } from '../../api/tasks';
import { Label } from 'reactstrap';
import '../../style/layout/Forms.css';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    paddingBottom: '5vh',
  },
  MuiDialogContentText: {
    fontSize: 18,
    color: 'black',
    paddingTop: 20,
  },
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
}));

const DepartmentTask = ({
  departmentTaskLists,
  setDepartmentTaskLists,
  addSite,
  reset,
}: {
  departmentTaskLists: { departmentId: string; name: string; taskIds: string[] }[];
  setDepartmentTaskLists: (
    list: { departmentId: string; name: string; taskIds: string[] }[]
  ) => void;
  addSite: (e: any) => void;
  reset: () => void;
}): JSX.Element => {
  const classes = useStyles();
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const get = async () => {
      const response = await getTasks();
      setTasks(response);
    };
    get();
  }, []);

  const handleTasks = (e: any, departmentId: any) => {
    const departmentTaskListTemp = [...departmentTaskLists];
    const departmentIndex = departmentTaskListTemp.findIndex(
      (element) => element.departmentId === departmentId
    );
    departmentTaskListTemp[departmentIndex].taskIds = e.target.value;
    setDepartmentTaskLists(departmentTaskListTemp);
  };

  return (
    <>
      <form onSubmit={(e: any) => addSite(e)} onReset={reset}>
        <DialogContent>
          <DialogContentText classes={{ root: classes.MuiDialogContentText }}>
            {common.siteDialog.tasks}
          </DialogContentText>
          {departmentTaskLists.map((option) => (
            <div key={option.departmentId}>
              <Label>{option.name}</Label>
              <Select
                fullWidth
                id="department"
                multiple
                value={option.taskIds}
                className="tasks-dropdown"
                onChange={(e: any) => handleTasks(e, option.departmentId)}
              >
                {tasks.map((option: any) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <DialogButtons />
        </DialogActions>
      </form>
    </>
  );
};

export default DepartmentTask;
