import { api } from './fetchUtils';

export const getSites = async () => {
  try {
    const response = await api.get('/api/Sites');
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    console.log('Error: ', err);
    return [];
  }
};

export const addSite = async (site: any) => {
  try {
    const response = await api.post('/api/Sites', site);
    if (response.status === 200) {
      return response.status;
    }
  } catch (err) {
    console.log('Error: ', err);
    return null;
  }
};

export const updateSite = async (site: any, id: string) => {
  try {
    const response = await api.put(`/api/Sites/${id}`, site);
    if (response.status === 200) {
      return response.status;
    }
  } catch (err) {
    console.log('Error: ', err);
    return null;
  }
};

export const deleteSite = async (id: string) => {
  try {
    const response = await api.delete(`/api/Sites/${id}`);
    if (response.status === 200) {
      return response.status;
    }
  } catch (err) {
    console.log('Error: ', err);
    return null;
  }
};
