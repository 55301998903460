import MaterialTable from 'material-table';
import { useState } from 'react';
import Site from '../Dialogs/Site';
import common from '../../strings/common.json';
import { useEffect } from 'react';
import { deleteSite, getSites } from '../../api/sites';
import { getDepartmentApi } from '../../api/departments';
import LoadIndicator from '../../components/LoadIndicator';

const Sites = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (value: any) => void;
}): JSX.Element => {
  const [sites, setSites] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedSite, setSelectedSite] = useState();
  const [refreshResults, setRefreshResults] = useState(true);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      field: 'name',
    },
  ];
  const actions = [
    {
      icon: 'edit',
      tooltip: common.siteDialog.edit,
      // @ts-ignore
      onClick: (e: any, rowData: any) => selectSite(e, rowData),
    },
    {
      icon: 'delete',
      tooltip: common.siteDialog.delete,
      // @ts-ignore
      onClick: (e: any, rowData: any) => deleteSelectedTask(e, rowData),
    },
  ];

  const deleteSelectedTask = async (e: any, rowData: any) => {
    if (window.confirm(common.siteDialog.confirmDelete)) {
      const response = await deleteSite(rowData.id);
      if (response !== 200) {
        window.alert(common.siteDialog.errorDelete);
        return;
      }
      setRefreshResults(true);
    }
  };

  const selectSite = (e: any, rowData: any) => {
    setSelectedSite(rowData);
    setOpen(true);
  };

  useEffect(() => {
    const get = async () => {
      setLoading(true);
      const response = await getSites();
      setSites(response);
      setLoading(false);
    };
    if (refreshResults) {
      get();
      setRefreshResults(false);
    }
  }, [refreshResults]);

  useEffect(() => {
    const get = async () => {
      const response = await getDepartmentApi();
      setDepartments(response);
    };
    get();
  }, []);

  if (loading) {
    return <LoadIndicator />;
  }
  return (
    <>
      <MaterialTable
        title=""
        localization={{
          header: {
            actions: '',
          },
        }}
        columns={columns}
        data={sites}
        actions={actions}
        options={{
          actionsColumnIndex: -1,
          sorting: false,
          draggable: false,
          toolbar: false,
          header: false,
          paging: false,
        }}
      />
      <Site
        open={open}
        setOpen={setOpen}
        refreshPage={() => setRefreshResults(true)}
        selectedSite={selectedSite}
        setSelectedSite={setSelectedSite}
        departments={departments}
      />
    </>
  );
};

export default Sites;
