import { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { MenuItem } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CheckboxWithLabel } from 'formik-material-ui';
import { Form, Field, Formik } from 'formik';
import * as yup from 'yup';
import { addUser, updateUser } from '../../api/users';
import { DialogContext } from '../../components/context';
import DialogButtons from '../../components/layout/DialogButtons';
import { getCompaniesApi } from '../../api/companies';
import common from '../../strings/common.json';
import { resetValidity, setEmptyValidityMessage } from '../../components/helpers/emptyValidation';

const useStyles = makeStyles({
  dialogPaper: {
    paddingBottom: '5vh',
  },
});

const validationSchema = yup.object({
  password: yup
    //@ts-ignore
    .string(common.employeeDialog.enterPassword)
    .required(common.employeeDialog.passwodRequired)
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      common.employeeDialog.passwordRequirements
    ),

  passwordConfirmation: yup
    .string()
    //@ts-ignore
    .oneOf([yup.ref('password'), null], common.employeeDialog.passwordMatch),
});

const Employee = ({
  refreshPage,
  selectedEmployee,
  setSelectedEmployee,
}: {
  refreshPage: () => void;
  selectedEmployee: any;
  setSelectedEmployee: (value: any) => void;
}): JSX.Element => {
  const { addEmployee, setAddEmployee } = useContext(DialogContext);
  const [showPassFields, setShowPassFields] = useState(true);

  const [companies, setCompanies] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (selectedEmployee !== undefined) {
      setShowPassFields(false);
    } else {
      setShowPassFields(true);
    }
  }, [selectedEmployee]);

  useEffect(() => {
    const getCompanies = async () => {
      const result = await getCompaniesApi();
      setCompanies(result);
    };
    getCompanies();
  }, []);

  const add = async (values: {}) => {
    const response = await addUser(values);
    if (response !== 200) {
      window.alert(common.employeeDialog.errorAdd);
      return;
    }
    refreshPage();
  };

  const update = async (values: {}) => {
    const response = await updateUser(values, selectedEmployee.id);
    if (response !== 200) {
      window.alert(common.employeeDialog.errorEdit);
      return;
    }

    refreshPage();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      classes={{ paper: classes.dialogPaper }}
      open={addEmployee}
    >
      <Formik
        initialValues={
          selectedEmployee
            ? {
                name: selectedEmployee.name,
                company: selectedEmployee.company.id,
                personnelNumber: selectedEmployee.personnelNumber,
                flag: false,
                password: '',
                passwordConfirmation: '',
              }
            : {
                name: '',
                company: '',
                personnelNumber: '',
                password: '',
                passwordConfirmation: '',
              }
        }
        validationSchema={showPassFields ? validationSchema : null}
        onSubmit={(values, { resetForm }) => {
          if (selectedEmployee) {
            const employeeData = {
              name: values.name,
              companyId: values.company,
              personnelNumber: values.personnelNumber,
              newPassword: showPassFields ? values.password : null,
            };

            update(employeeData);
          } else {
            const employeeData = {
              name: values.name,
              companyId: values.company,
              personnelNumber: values.personnelNumber,
              password: values.password,
            };
            add(employeeData);
          }
          resetForm({});
          setAddEmployee(false);
          setSelectedEmployee(undefined);
        }}
        onReset={() => {
          setAddEmployee(false);
          setSelectedEmployee(undefined);
        }}
      >
        {({ values, handleChange, touched, errors }) => (
          <Form>
            <DialogTitle id="form-dialog-title">
              {selectedEmployee ? common.employeeDialog.edit : common.employeeDialog.newEmployee}
            </DialogTitle>
            <DialogContent>
              <div style={{ width: '60%' }}>
                <TextField
                  fullWidth
                  required
                  onInput={(e) => resetValidity(e)}
                  onInvalid={(e) => setEmptyValidityMessage(e)}
                  id="name"
                  name="name"
                  label={common.employeeDialog.employeeName}
                  value={values.name}
                  onChange={handleChange}
                />
                <TextField
                  select
                  fullWidth
                  required
                  value={values.company}
                  id="company"
                  name="company"
                  label={common.employeeDialog.chooseCompany}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                >
                  {companies?.map((company: any) => (
                    <MenuItem key={company.id} value={company.id}>
                      {company.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  required
                  id="personnelNumber"
                  name="personnelNumber"
                  label={common.employeeDialog.personalNumber}
                  value={values.personnelNumber}
                  onChange={handleChange}
                />
                {selectedEmployee ? (
                  <Field
                    component={CheckboxWithLabel}
                    checked={showPassFields}
                    type="checkbox"
                    name="flag"
                    Label={{ label: common.employeeDialog.changeUsersPassword }}
                    onChange={() => setShowPassFields(!showPassFields)}
                  />
                ) : null}
                {showPassFields ? (
                  <>
                    <TextField
                      fullWidth
                      required
                      id="password"
                      name="password"
                      label={common.employeeDialog.password}
                      type="password"
                      value={values.password}
                      onChange={handleChange}
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                    />
                    <TextField
                      fullWidth
                      required
                      id="passwordConfirmation"
                      name="passwordConfirmation"
                      label={common.employeeDialog.confirmPAssword}
                      type="password"
                      value={values.passwordConfirmation}
                      onChange={handleChange}
                      error={touched.passwordConfirmation && Boolean(errors.passwordConfirmation)}
                      helperText={touched.passwordConfirmation && errors.passwordConfirmation}
                    />
                  </>
                ) : null}
              </div>
            </DialogContent>
            <DialogActions>
              <DialogButtons />
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default Employee;
