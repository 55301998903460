import { api } from './fetchUtils';

export const getUsers = async () => {
  try {
    const response = await api.get('/api/Users/employees');
    if (response.status === 200) {
      return response.data;
    }
  } catch (err) {
    console.log('Error: ', err);
    return [];
  }
};

export const addUser = async (values: {}) => {
  try {
    const response = await api.post('/api/Users/employees', values);
    return response.status;
  } catch (err) {
    console.log('Error: ', err);
    return null;
  }
};

export const updateUser = async (values: {}, id: string) => {
  try {
    const response = await api.put(`/api/Users/employees/${id}`, values);
    return response.status;
  } catch (err) {
    console.log('Error: ', err);
    return null;
  }
};

export const deleteUser = async (id: string) => {
  try {
    const response = await api.delete(`/api/Users/employees/${id}`);
    return response.status;
  } catch (err) {
    console.log('Error: ', err);
    return null;
  }
};
